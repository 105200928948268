import React, { useCallback, useContext } from "react";
import Styles from "./index.module.scss";
import Button from "../../widgets/Button";
import tittleImg from "../../assets/birth.png";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import PlanList from "../../components/PlanList";
import CommonInputField from "../../widgets/CommonInputField";
import DragAndDrop from "../../components/DragAndDrop";
import Man from "../../assets/man.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneNumber from "../../components/PhoneInput";
import TimeRange from "../../components/TimeRange";
import Modal from "../../modal";
import BookSlot from "../../modal/BookSlot";
import { endpoints } from "../../utils/Endpoints";
import CommonSelect from "../../components/CommonSelect";
import swal from "sweetalert";
import { Context } from "../../utils/ContextStor";
import { useNavigate } from "react-router-dom/dist";
import Login from "../../modal/Login";
import SignUp from "../../modal/SignUp";
import Loading from "../../components/Loading";
import noDataFoundImg from "../../assets/noData.png";
// import pointer from "../../assets/pointer.webp";
import { loadScript } from "../../helpers/razorpay";
import ModalLayout from "../../components/ModalLayout";
import PaymentOption from "../../components/PaymentOption";
import phonPayimg from "../../assets/PhonePe_Logo.svg.png";
// eslint-disable-next-line
import razorPayimg from "../../assets/Razorpay_payments.png";
import closeImg from "../../assets/close.svg";
import Logo from "../../assets/LOGO.webp";

const genderList = [
  { id: 1, name: "Select" },
  { id: 2, name: "Male" },
  { id: 3, name: "Female" },
  { id: 4, name: "Other" },
];
const languageList = [
  { id: 1, name: "Select" },
  { id: 2, name: "Hindi" },
  { id: 3, name: "English" },
];

const BirthTimeRectification = () => {
  const [hourCount, setHourCount] = useState(null);
  const [signup, setSignup] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState();
  const [image1, setImage1] = useState("");
  const [document1, setDucument1] = useState();
  const [showDoc, setShowDoc] = useState();
  const [submit, setSubmit] = useState(true);
  const [select, setSelect] = useState("");
  const [birthTimePlans, setBirthTimePlans] = useState([]);
  const [start, setStart] = useState("12:00");
  const [end, setEnd] = useState("13:00");
  const [timeofbirth, setTimeOfBirth] = useState("");
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [slotTime, setSlotTime] = useState("Your Slot Time");
  const [slotDate, setSlotDate] = useState("Your Slot Date");
  const [showLoader, setShowLoader] = useState(true);
  const [birthData, setBirthData] = useState({
    name: "",
    email: "",
    Language: languageList[0].name,
    height: "",
    Gender: genderList[0].name,
    placeOfBirth: "",
  });
  const [price, setPrice] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  // ********************** Get Data Localstorage ***************
  // eslint-disable-next-line
  const [token, setToken] = useContext(Context);
  const navigate = useNavigate();
  let astroData = JSON.parse(localStorage.getItem("astroDet"));
  const user = JSON.parse(localStorage.getItem("user"));
  const formObj = [
    {
      inputName: "Name",
      inputType: "text",
      display: "none",
      name: "name",
      id: "name",
      value: birthData.name,
    },
    {
      inputName: "dob",
      inputType: "text",
      display: "none",
      name: "dob",
      id: "dob",
      value: birthData.name,
    },
    {
      inputName: "tob",
      inputType: "text",
      display: "none",
      name: "tob",
      id: "tob",
      value: birthData.name,
    },
    {
      inputName: "Place Of Birth",
      inputType: "text",
      display: "none",
      name: "placeOfBirth",
      id: "placeOfBirth",
      value: birthData.placeOfBirth,
    },
    {
      inputName: "gender",
      inputType: "text",
      display: "none",
      name: "gender",
      id: "gender",
      value: birthData.placeOfBirth,
    },
    {
      inputName: "Email Id",
      inputType: "email",
      display: "none",
      name: "email",
      id: "email",
      value: birthData.email,
    },
    {
      inputName: "phone",
      inputType: "text",
      display: "none",
      name: "phone",
      id: "phone",
      value: birthData.email,
    },
    {
      inputName: "Height In CM",
      inputType: "number",
      display: "none",
      name: "height",
      id: "height",
      value: birthData.height,
    },
  ];

  // ******************Hour Count**************
  const calculateHourCount = () => {
    const starttime = new Date(`01/01/2000 ${start}`);
    const endtime = new Date(`01/01/2000 ${end}`);

    // Calculate the difference in milliseconds
    const difference = Math.abs(endtime - starttime);

    // Convert milliseconds to hours
    const hourDifference = difference / (1000 * 60 * 60);

    setHourCount(hourDifference);
  };
  // *****************************************
  const onSelectedSlot = (date, time) => {
    setSlotDate(date);
    setSlotTime(time);
    setModalIsVisible(false);
  };
  const onCloseMoadal = () => {
    setModalIsVisible(false);
    setPaymentModal(false);
  };
  const onChangeHandler = (e, id) => {
    setBirthData({
      ...birthData,
      [id]: e.target.value,
    });
  };

  // ***************************** Submit Data **********************************
  const bookingHandler = (paymentId, paymentOption) => {
    const formData = new FormData();
    formData.append("astrologer_id", astroData.astrologerID);
    formData.append("predection_type_id", astroData.serviceType);
    formData.append("predection_id", astroData.servicId);
    formData.append("name", birthData.name);
    formData.append("gender", birthData.Gender);
    formData.append("language", birthData.Language);
    formData.append("date_of_birth", selectedDate);
    formData.append("time_of_birth", timeofbirth);
    formData.append("place_of_birth", birthData.placeOfBirth);
    formData.append("emailid", birthData.email);
    formData.append("contact_no", phone);
    formData.append("height", birthData.height);
    formData.append("bimage", image1);
    formData.append("plan_id", select);
    formData.append("slot_time", slotTime);
    formData.append("slot_date", slotDate);
    formData.append("payment_id", paymentId);
    formData.append("pg_type", paymentOption);
    const postData = {
      url: endpoints.birthTimeRectification,
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: formData,
    };
    axios(postData)
      .then((res) => {
        if (res.data.status === true) {
          setSubmit(true);
          window.location.replace(res.data.url);
        } else {
          swal("Sorry :(", res.data.message);
          setSubmit(true);
        }
      })
      .catch((err) => {
        swal("Failed!", "Booking Failed!", "error");
        setSubmit(true);
      });
  };

  const onSubmitHandler = async (paymentOption) => {
    // eslint-disable-next-line
    const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    setSubmit(false);

    // ***************************** Set Data **********************************

    if (birthData.name === "") {
      setSubmit(true);
      swal("Failed!", "Name is required", "error");
    } else if (specialChars.test(birthData.name)) {
      setSubmit(true);
      swal("Failed!", "Name should not contain special characters", "error");
    } else if (selectedDate === new Date()) {
      setSubmit(true);
      swal("Failed!", "Date Of Birth is required", "error");
    } else if (end === "") {
      setSubmit(true);
      swal("Failed!", "Time Of Birth is required", "error");
    } else if (birthData.placeOfBirth === "") {
      setSubmit(true);
      swal("Failed!", "Place Of Birth is required", "error");
    } else if (birthData.Gender === "Select") {
      setSubmit(true);
      swal("Failed!", "Gender is required", "error");
    } else if (birthData.email === "" || !birthData.email.includes("@")) {
      setSubmit(true);
      swal("Failed!", "Email is required", "error");
    } else if (phone === "" || phone === undefined) {
      setSubmit(true);
      swal("Failed!", "Phone No. is required", "error");
    } else if (birthData.Language === "Select") {
      setSubmit(true);
      swal("Failed!", "Language is required", "error");
    } else if (birthData.height === "" || !birthData.height.type === Number) {
      setSubmit(true);
      swal("Failed!", "Height must be a number", "error");
    } else if (image1 === "") {
      setSubmit(true);
      swal("Failed!", "Image is required", "error");
    } else if (select === "") {
      setSubmit(true);
      swal("Failed!", "Please select Plan", "error");
    } else if (slotDate === "Your Slot Date") {
      setSubmit(true);
      swal("Failed!", "Please Choose Date", "error");
    } else if (slotTime === "Your Slot Time") {
      setSubmit(true);
      swal("Failed!", "Please Choose Time", "error");
    } else if (paymentOption === "") {
      setPaymentModal(true);
    } else {
      if (user) {
        // **********************************RazorPay**************************************
        if (paymentOption === "razorpay") {
          const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
          if (!res) {
            alert("You are offline ...Failed to load Razorpay SDK", {
              type: "warning",
            });
            return;
          } else {
            const options = {
              key: "rzp_test_gZte4p2li4zBnk",
              currency: "INR",
              amount: price * 100,
              name: "Adhigyanam",
              description: "Thanks for purchasing",
              image: Logo,
              prifill: {
                name: user?.name, //your customer's name
                email: user?.email,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              handler: function (response) {
                if (response) {
                  bookingHandler(response.razorpay_payment_id, paymentOption);
                } else {
                  swal("Failed!", "Payment Failed!", "error");
                }
              },
              // callback_url: "https://dev.adhigyanam.com/admin/razorpay-response",
              // redirect: true,
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        } else {
          bookingHandler("", paymentOption);
        }
      } else {
        swal("Failed!", "Login first and proceed to Pay!", "error");
        setSubmit(true);
        setLoginModal(true);
      }
    }
  };
  // *******************************************************************************

  const filesHandlerOne = (e) => {
    let path = e.target.files[0];
    setImage1(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setDucument1(image);
      setShowDoc(true);
    }
  };
  const onSelectPlanHandler = (id, price) => {
    // setSelect(id);
    // setPrice(price);
  };
  const onSelectDateHandler = (date) => {
    let userDob = new Date(date);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    setStartDate(date);
  };
  useEffect(() => {
    let userDob = new Date(startDate);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    // eslint-disable-next-line
  }, []);
  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };
  useEffect(() => {
    const setPlan = {
      url: "https://dev.adhigyanam.com/admin/api/planService-get",
      method: "POST",
      data: {
        predection_id: astroData?.servicId,
        predection_type_id: astroData?.serviceType,
      },
    };
    //****************Plans************* */
    axios(setPlan)
      .then((res) => {
        setBirthTimePlans(res.data.data);
        setShowLoader(false);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);
  // ************** Time Range *******************
  const convertTime = (val) => {
    let h = parseInt(val.split(":")[0]);
    let hour = h === 0 ? 12 : h > 12 ? h - 12 : h;
    let updatedHour = hour < 10 ? "0" + hour : hour;
    let updatedMin = start.split(":")[1];
    let session = h >= 12 ? "PM" : "AM";

    let time = `${updatedHour}:${updatedMin} ${session}`;

    return time;
  };

  const startTime = (e) => {
    setStart(e.target.value);
    setEnd("");
  };
  const endTime = (e) => {
    setEnd(e.target.value);
  };

  useEffect(() => {
    setTimeOfBirth(convertTime(start) + "-" + convertTime(end));
    calculateHourCount();
    // eslint-disable-next-line
  }, [convertTime]);
  const chooseYourBookSlot = () => {
    setModalIsVisible(true);
  };
  console.log(hourCount);

  const onCanceHandler = () => {
    setBirthData({
      name: "",
      email: "",
      Language: languageList[0].name,
      height: "",
      Gender: genderList[0].name,
      placeOfBirth: "",
    });
    setSlotTime("Your Slot Time");
    setSlotDate("Your Slot Date");
    setPhone("");
    setSelect(0);
  };
  // **************************Loging************************************
  const onLogin = useCallback(async (userDetails) => {
    setToken(userDetails.token);
    setLoginModal(false);
    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speciality: userDetails.speciality,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.Gender,
      contact_no: userDetails.contact_no,
      language: userDetails.Language,
    };
    localStorage.setItem("token", userDetails.token);
    localStorage.setItem("user", JSON.stringify(filteredData));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);

    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);

  const onSignupHandler = () => {
    setLoginModal(false);
    setSignup(true);
  };
  const onCancelSignUp = () => {
    setLoginModal(true);
    setSignup(false);
  };
  const onCloseModal = () => {
    setLoginModal(false);
    setSignup(false);
  };
  const onClickPaymentOption = (payOption) => {
    setPaymentModal(false);
    onSubmitHandler(payOption);
  };
  // const onShowPaymentModal = () => {
  //   setPaymentModal(true);
  // };

  // console.log(birthTimePlans);

  useEffect(() => {
    birthTimePlans.find((item) => {
      if (hourCount > item?.min_hour && hourCount <= item?.max_hour) {
        setSelect(item.id);
        setPrice(item.price);
      }
      return null;
    });
    // eslint-disable-next-line
  }, [hourCount, birthTimePlans]);

  return (
    <>
      {paymentModal && (
        <ModalLayout onCloseHandler={onCloseMoadal}>
          <Modal onClose={onCloseMoadal} height="auto" overflowY="auto" width="50%">
            <div className={Styles.paymentOption}>
              <h1>Choose Payment Option</h1>
              <span className={Styles.paymentOption__cros} onClick={onCloseMoadal}>
                <img src={closeImg} alt="close" />
              </span>
              <div className={Styles.paymentOption__option}>
                <PaymentOption
                  name="pay"
                  img={phonPayimg}
                  id="phonepay"
                  value="phonepay"
                  onClick={() => onClickPaymentOption("phonepay")}
                />
                {/* <PaymentOption
                  name="pay"
                  img={razorPayimg}
                  id="razorpay"
                  value="razorpay"
                  onClick={() => onClickPaymentOption("razorpay")}
                /> */}
              </div>
            </div>
          </Modal>
        </ModalLayout>
      )}
      {modalIsVisible && (
        <Modal onClose={onCloseMoadal}>
          <BookSlot display="none" width="100%" padding="0" onClick={onSelectedSlot} modalIsVisible={modalIsVisible} />
        </Modal>
      )}
      {loginModal && (
        <Modal onClose={onCloseModal}>
          <Login onSignupHandler={onSignupHandler} onCloseHandler={onCloseModal} onLoginHandler={onLogin} />
        </Modal>
      )}
      {signup && (
        <Modal onClose={onCloseModal}>
          <SignUp setSignup={setSignup} onCloseHandler={onCloseModal} onCancelSignUp={onCancelSignUp} />
        </Modal>
      )}
      <div className={Styles.modalArea}>
        <CommanTitleWithLogo tittle="Birth Time Rectification" icon={tittleImg} width2="48px" height="50px" />
        <div className={Styles.modalArea__modal}>
          <div className={Styles.modalArea__modal__formArea}>
            <div className={Styles.modalArea__modal__formArea__detail}>
              <span>
                <h2>Please Fill Some Details</h2>
              </span>
              <div className={Styles.modalArea__modal__formArea__detail__formField}>
                {formObj.map((item, index) => {
                  if (item.id === "dob") {
                    return (
                      <div className={Styles.modalArea__modal__formArea__detail__formField__select}>
                        <p>Date of Birth</p>
                        <DatePicker
                          maxDate={new Date()}
                          className={Styles.datePicker}
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          onChange={onSelectDateHandler}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    );
                  } else if (item.id === "tob") {
                    return (
                      <div className={Styles.modalArea__modal__formArea__detail__formField__select}>
                        <p>Time of Birth</p>
                        <TimeRange value1={start} value2={end} startTime={startTime} endTime={endTime} />
                      </div>
                    );
                  } else if (item.id === "gender") {
                    return <CommonSelect value={birthData.Gender} label="Gender" list={genderList} onChange={onChangeHandler} />;
                  } else if (item.id === "phone") {
                    return (
                      <div className={Styles.modalArea__modal__formArea__detail__formField__select}>
                        <p>Enter Phone</p>
                        <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneHandler} />
                      </div>
                    );
                  } else {
                    return (
                      <CommonInputField
                        onChange={(e) => onChangeHandler(e, item.id)}
                        name={item.name}
                        key={index}
                        type={item.inputType}
                        placeholder={item.inputName}
                        display={item.display}
                        borderColor="#1eb2a6"
                        value={item.value}
                      />
                    );
                  }
                })}

                <CommonSelect value={birthData.Language} label="Language" list={languageList} onChange={onChangeHandler} />

                <Button
                  width="100%"
                  marginTop="0"
                  marginBottom="0"
                  fontSize="18px"
                  btnName="Choose Slots"
                  textTransform="capitalize"
                  onClick={chooseYourBookSlot}
                />
                <p className={Styles.modalArea__modal__formArea__detail__formField__timeSlot}>{slotTime}</p>
                <p className={Styles.modalArea__modal__formArea__detail__formField__timeSlot}>{slotDate}</p>
                {/* {paymentOption && (
                  <div className={Styles.showPayment}>
                    <p>
                      Payment Option : <span>{paymentOption}</span>{" "}
                    </p>
                    <span>
                      <i onClick={onShowPaymentModal} class="fa-solid fa-pen-to-square"></i>
                    </span>
                  </div>
                )} */}
              </div>
              <div className={Styles.modalArea__modal__formArea__detail__buttonArea}>
                <div className={Styles.modalArea__modal__formArea__detail__buttonArea__dragDrop}>
                  <DragAndDrop filesHandler={filesHandlerOne} image={document1} showFile={showDoc} height="120px" fontSize="14px" />
                </div>
                <div className={Styles.modalArea__modal__formArea__detail__buttonArea__man}>
                  <img src={Man} alt="man" />
                </div>
                <Button
                  btnName="Cancel"
                  fontSize="20px"
                  textTransform="capitalize"
                  backgroundColor="#c4c4c4"
                  border="1px solid #c4c4c4"
                  color="#333"
                  width="180px"
                  onClick={onCanceHandler}
                />
                <Button
                  onClick={() => onSubmitHandler("")}
                  btnName={submit ? "Submit" : "Processing.."}
                  fontSize="20px"
                  textTransform="capitalize"
                  width="180px"
                />
              </div>
            </div>
            <div className={Styles.modalArea__modal__formArea__plane}>
              <>
                {showLoader ? (
                  <Loading />
                ) : (
                  <>
                    {birthTimePlans.length <= 0 ? (
                      <span className={Styles.emptyMsg}>
                        <img src={noDataFoundImg} alt="err" />
                      </span>
                    ) : (
                      <>
                        {/* <h4>
                          <p>Select Any Plan</p>
                          <span>
                            <img src={pointer} alt="err" />
                          </span>
                        </h4> */}
                        {birthTimePlans.map((item, index) => {
                          return (
                            <PlanList
                              onClick={() => onSelectPlanHandler(item.id, item.price)}
                              key={index}
                              fontSize="18px"
                              planNo={item.name}
                              plan={item.description}
                              // width="34%"
                              selected={select === item.id}
                              cursor="pointer"
                              planWidth="auto"
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BirthTimeRectification;
