import React, { useCallback, useContext, useEffect } from "react";
import Styles from "./index.module.scss";
import CommonTitle from "../../components/CommonTitle";
import Button from "../../widgets/Button";
import CommonInputField from "../../widgets/CommonInputField";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import graphology from "../../assets/p7.webp";
import DragAndDrop from "../../components/DragAndDrop";
import handwriting from "../../assets/handwriting.png";
import swal from "sweetalert";
import { useState } from "react";
import axios from "axios";
import { endpoints } from "../../utils/Endpoints";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../modal";
import BookSlot from "../../modal/BookSlot";
import PhoneNumber from "../../components/PhoneInput";
import CommonSelect from "../../components/CommonSelect";
import SignUp from "../../modal/SignUp";
import Login from "../../modal/Login";
import { Context } from "../../utils/ContextStor";
import { loadScript } from "../../helpers/razorpay";
import Logo from "../../assets/LOGO.webp";
import PaymentOption from "../../components/PaymentOption";
import phonPayimg from "../../assets/PhonePe_Logo.svg.png";
// eslint-disable-next-line
import razorPayimg from "../../assets/Razorpay_payments.png";
import ModalLayout from "../../components/ModalLayout";
import closeImg from "../../assets/close.svg";

const GraphologyForm = () => {
  const languageList = [
    { id: 1, name: "Select" },
    { id: 2, name: "Hindi" },
    { id: 3, name: "English" },
  ];
  const [signup, setSignup] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [submit, setSubmit] = useState(true);
  const [phone, setPhone] = useState("");

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [slotTime, setSlotTime] = useState("Your Slot time");
  const [slotDate, setSlotDate] = useState("Your Slot date");
  const [image1, setImage1] = useState("");
  const [document1, setDucument1] = useState();
  const [showDoc, setShowDoc] = useState();
  const [graphologyData, setGraphologyData] = useState({
    name: "",
    Language: languageList[0].name,
    email: "",
  });
  // ********************************Payment Conde*****************************
  const [paymentModal, setPaymentModal] = useState(false);
  // *************************************************************************
  // eslint-disable-next-line
  const [token, setToken] = useContext(Context);
  const navigate = useNavigate();
  const params = useParams();
  let astroData = JSON.parse(localStorage.getItem("allGraphologyData"));
  const user = JSON.parse(localStorage.getItem("user"));
  let pathId = JSON.parse(localStorage.getItem("predictionId"));
  const formObj = [
    {
      id: "name",
      value: graphologyData.name,
      type: "text",
      placeholder: "Name",
      name: "name",
      display: "none",
    },

    {
      id: "email",
      value: graphologyData.email,
      type: "email",
      placeholder: "Email Id",
      name: "email",
      display: "none",
    },
  ];
  // ********************** OnChange *******************************
  const onChangeHandler = (e, id) => {
    setGraphologyData({
      ...graphologyData,
      [id]: e.target.value,
    });
  };

  const filesHandlerOne = (e) => {
    let path = e.target.files[0];
    setImage1(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setDucument1(image);
      setShowDoc(true);
    }
  };
  // ********************* Submit Data ************************
  const onSelectedSlot = (date, time) => {
    setSlotDate(date);
    setSlotTime(time);
    setModalIsVisible(false);
  };
  // *************************Submit***********************

  const bookingHandler = async (paymentId, paymentOption) => {
    const formData = new FormData();
    formData.append("astrologer_id", astroData?.asrtoDetail?.astrologerID);
    formData.append("consultation_type", astroData?.consultationType);
    formData.append("consultation_id[]", astroData?.consultationList);
    formData.append("name", graphologyData.name);
    formData.append("language", graphologyData.Language);
    formData.append("contact_no", phone);
    formData.append("emailid", graphologyData.email);
    formData.append("sample", image1);
    formData.append("slot_time", slotTime);
    formData.append("slot_date", slotDate);
    formData.append("predection_id", pathId);
    formData.append("consultation_type_id", astroData?.consultationTypeId);
    formData.append("payment_id", paymentId);
    formData.append("pg_type", paymentOption);

    const postData = {
      url: endpoints.graphologyStor,
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: formData,
    };
    axios(postData)
      .then((res) => {
        if (res.data.status === true) {
          swal("Successful!", "Sucessfully Submit", "success");
          setSubmit(true);

          window.location.replace(res.data.url);
        } else {
          swal("Sorry :(", res.data.message);
          setSubmit(true);
        }
      })
      .catch((err) => {
        swal("Failed!", "Somthing Wrong", "error");
        setSubmit(true);
      });
  };

  const onSubmitHandler = async (paymentOption) => {
    // eslint-disable-next-line
    const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    setSubmit(false);

    if (graphologyData.name === "") {
      swal("Failed!", "Name is required", "error");
      setSubmit(true);
    } else if (specialChars.test(graphologyData.name)) {
      setSubmit(true);
      swal("Failed!", "Name should not contain special characters", "error");
    } else if (graphologyData.email === "" || !graphologyData.email.includes("@") || !graphologyData.email.includes(".")) {
      swal("Failed!", "Please Enter Valid Email", "error");
      setSubmit(true);
    } else if (phone === "" || phone === undefined) {
      swal("Failed!", "Phone is required", "error");
      setSubmit(true);
    } else if (graphologyData.Language === "Select") {
      swal("Failed!", "Language is required", "error");
      setSubmit(true);
    } else if (image1 === "") {
      swal("Failed!", "Image is required", "error");
      setSubmit(true);
    } else if (slotDate === "Your Slot date") {
      swal("Failed!", "Please Select any Slot", "error");
      setSubmit(true);
    } else if (slotTime === "Your Slot time") {
      swal("Failed!", "Please Select any Slot", "error");
      setSubmit(true);
    } else if (paymentOption === "") {
      setPaymentModal(true);
    } else {
      if (user) {
        // **********************************RazorPay**************************************
        if (paymentOption === "razorpay") {
          const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
          if (!res) {
            alert("You are offline ...Failed to load Razorpay SDK", {
              type: "warning",
            });
            return;
          } else {
            const options = {
              key: "rzp_test_gZte4p2li4zBnk",
              currency: "INR",
              amount: astroData?.planPrice * 100,
              name: "Adhigyanam",
              description: "Thanks for purchasing",
              image: Logo,
              prifill: {
                name: user?.name, //your customer's name
                email: user?.email,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              handler: function (response) {
                if (response) {
                  bookingHandler(response.razorpay_payment_id, paymentOption);
                } else {
                  swal("Failed!", "Payment Failed!", "error");
                }
              },
              // callback_url: "https://dev.adhigyanam.com/admin/razorpay-response",
              // redirect: true,
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        } else {
          bookingHandler("", paymentOption);
        }
      } else {
        swal("Failed!", "Login first and Proceed to Pay!", "error");
        setSubmit(true);
        setLoginModal(true);
      }
    }
  };
  const chooseYourBookSlot = () => {
    setModalIsVisible(true);
  };
  const onCloseMoadal = () => {
    setModalIsVisible(false);
    setPaymentModal(false);
  };
  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };

  const onCancelHandler = () => {
    setGraphologyData({
      name: "",
      Language: languageList[0].name,
      email: "",
    });
    setPhone("");
    setShowDoc(false);
    setDucument1();
    setImage1("");
  };
  // **************************Loging************************************
  const onLogin = useCallback(async (userDetails) => {
    setToken(userDetails.token);
    setLoginModal(false);
    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speciality: userDetails.speciality,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.Gender,
      contact_no: userDetails.contact_no,
      language: userDetails.Language,
    };
    localStorage.setItem("token", userDetails.token);
    localStorage.setItem("user", JSON.stringify(filteredData));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);

    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);

  const onSignupHandler = () => {
    setLoginModal(false);
    setSignup(true);
  };
  const onCancelSignUp = () => {
    setLoginModal(true);
    setSignup(false);
  };
  const onCloseModal = () => {
    setLoginModal(false);
    setSignup(false);
  };
  const onClickPaymentOption = (payOption) => {
    setPaymentModal(false);
    onSubmitHandler(payOption);
  };

  // const onShowPaymentModal = () => {
  //   setPaymentModal(true);
  // };
  return (
    <>
      {paymentModal && (
        <ModalLayout onCloseHandler={onCloseMoadal}>
          <Modal onClose={onCloseMoadal} height="auto" overflowY="auto" width="50%">
            <div className={Styles.paymentOption}>
              <h1>Choose Payment Option</h1>
              <span className={Styles.paymentOption__cros} onClick={onCloseMoadal}>
                <img src={closeImg} alt="close" />
              </span>
              <div className={Styles.paymentOption__option}>
                <PaymentOption
                  name="pay"
                  img={phonPayimg}
                  id="phonepay"
                  value="phonepay"
                  onClick={() => onClickPaymentOption("phonepay")}
                />
                {/* <PaymentOption
                  name="pay"
                  img={razorPayimg}
                  id="razorpay"
                  value="razorpay"
                  onClick={() => onClickPaymentOption("razorpay")}
                /> */}
              </div>
            </div>
          </Modal>
        </ModalLayout>
      )}
      {modalIsVisible && (
        <Modal onClose={onCloseMoadal}>
          <BookSlot display="none" width="100%" padding="0" onClick={onSelectedSlot} modalIsVisible={modalIsVisible} />
        </Modal>
      )}
      {loginModal && (
        <Modal onClose={onCloseModal}>
          <Login onSignupHandler={onSignupHandler} onCloseHandler={onCloseModal} onLoginHandler={onLogin} />
        </Modal>
      )}
      {signup && (
        <Modal onClose={onCloseModal}>
          <SignUp setSignup={setSignup} onCloseHandler={onCloseModal} onCancelSignUp={onCancelSignUp} />
        </Modal>
      )}
      <div className={Styles.graphologyForm}>
        <div className={Styles.graphologyForm__top}>
          <CommanTitleWithLogo width="40%" tittle={params.name} icon={graphology} />
          {/* <CommonTab marginBottom="0" items={items} onClick={onConsultantType} /> */}
        </div>
        <div className={Styles.graphologyForm__content}>
          <div className={Styles.graphologyForm__content__left}>
            <div className={Styles.graphologyForm__content__left__tittle}>
              <CommonTitle tittle="Please Fill The Form" />
            </div>
            <div className={Styles.graphologyForm__content__left__formArea}>
              <div className={Styles.graphologyForm__content__left__formArea__input}>
                {formObj.map((item) => (
                  <CommonInputField
                    key={item.id}
                    onChange={(e) => onChangeHandler(e, item.id)}
                    id={item.name}
                    placeholder={item.placeholder}
                    display={item.display}
                    type={item.type}
                    value={item.value}
                    borderColor="#1eb2a6"
                  />
                ))}
                <div className={Styles.graphologyForm__content__left__formArea__input__select}>
                  <p>Enter Phone</p>
                  <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneHandler} />
                </div>
                <CommonSelect value={graphologyData.Language} label="Language" list={languageList} onChange={onChangeHandler} />
                <Button
                  width="100%"
                  fontSize="18px"
                  marginBottom="0"
                  btnName="Choose Slots"
                  textTransform="capitalize"
                  onClick={chooseYourBookSlot}
                />
                <p>{slotTime}</p>
                <p>{slotDate}</p>
                {/* {paymentOption && (
                  <div className={Styles.showPayment}>
                    <p>
                      Payment Option : <span>{paymentOption}</span>{" "}
                    </p>
                    <span>
                      <i onClick={onShowPaymentModal} class="fa-solid fa-pen-to-square"></i>
                    </span>
                  </div>
                )} */}
              </div>
            </div>
            <div className={Styles.graphologyForm__content__left__buttonArea}>
              <Button
                btnName="Cancel"
                fontSize="20px"
                backgroundColor="#c4c4c4"
                border="1px solid #c4c4c4"
                color="#333"
                textTransform="capitalize"
                width="70%"
                onClick={onCancelHandler}
              />
              <Button
                btnName={submit ? "Submit" : "Processing.."}
                fontSize="20px"
                textTransform="capitalize"
                width="70%"
                onClick={() => onSubmitHandler("")}
              />
            </div>
          </div>
          <div className={Styles.graphologyForm__content__right}>
            <DragAndDrop
              filesHandler={filesHandlerOne}
              image={document1}
              showFile={showDoc}
              fileType="Upload Hand Written Paragraph"
              fontSize="18px"
              marginTop="30px"
            />

            <div className={Styles.graphologyForm__content__right__sampleImg}>
              <h4>Sample Image</h4>
              <div className={Styles.graphologyForm__content__right__sampleImg__img}>
                <img src={handwriting} alt="handwriting" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GraphologyForm;
