import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import DragAndDrop from "../../DragAndDrop";
import DatePicker from "react-datepicker";
import PhoneNumber from "../../PhoneInput";
import axios from "axios";

const EditDet = ({
  onBackHandler,
  onSaveChangeHandler,
  onChangeHandler,
  userData,
  showDoc,
  filesHandlerOne,
  profile,
  onDateOfBirthHandler,
  onPhoneHandler,
  initPhone,
  loading,
  userDate,
}) => {
  const [serviceList, setServicesLists] = useState([]);
  const [startDate, setStartDate] = useState(userDate);
  const [selectedDate, setSelectedDate] = useState();
  const [phone, setPhone] = useState(initPhone);

  // ********************************************************
  const onSelectDateHandler = (date) => {
    setStartDate(date);
    let userDob = new Date(date);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    onDateOfBirthHandler(selectedDate);
  };
  useEffect(() => {
    let userDob = new Date(startDate);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    // eslint-disable-next-line
  }, []);
  onDateOfBirthHandler(selectedDate);

  const onPhoneChangeHandler = (ph) => {
    setPhone(ph);
  };
  onPhoneHandler(phone);

  useEffect(() => {
    const url = "https://dev.adhigyanam.com/admin/api/predection-services";

    axios
      .get(url)
      .then((res) => {
        if (res.data.data) {
          let val = res.data.data;
          setServicesLists(val);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <div className={styles.profileArea}>
        <h3 className={styles.profileArea__title}>Edit Profile</h3>
        <div className={styles.profileArea__etid}>
          <span style={{ cursor: "pointer" }} className={styles.profileArea__etid__back} onClick={onBackHandler}>
            <i className="fa-solid fa-arrow-left"></i>
          </span>
          <span className={styles.profileArea__etid__profilePic}>
            {/* <span className={styles.profileArea__etid__camera}><i className="fa-solid fa-camera"></i></span> */}
            <DragAndDrop height="120px" filesHandler={filesHandlerOne} image={profile} showFile={showDoc} />
          </span>
          <label htmlFor="name">Name</label>

          <input
            className={styles.profileArea__etid__input}
            type="text"
            name="name"
            value={userData?.name}
            id="name"
            onChange={(e) => onChangeHandler(e, "name")}
          />
          <label htmlFor="phone">Phone</label>

          <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneChangeHandler} />

          <div className={styles.profileArea__etid__selectArea}>
            <div>
              <label htmlFor="Service">Speacility</label>
              <select
                className={styles.profileArea__etid__selectArea__select}
                name="Service"
                id="speacility"
                value={userData?.speacility}
                onChange={(e) => onChangeHandler(e, "speacility")}>
                <option value="0">Select Service</option>;
                {serviceList.map((service) => {
                  return (
                    <option key={service.id} value={service.name}>
                      {service.name}
                    </option>
                  );
                })}
                {/* <option value="1">Astrology</option>
                <option value="2">Tarot Card Reading</option>
                <option value="3">Palmistry</option>
                <option value="4">Numrology</option>
                <option value="5">Vastu Analysis</option>
                <option value="6">Face Reading</option>
                <option value="7">Graphology</option>
                <option value="15">Signature Analysis</option> */}
              </select>
            </div>
            <div>
              <label htmlFor="language">Language</label>
              <select
                className={styles.profileArea__etid__selectArea__select}
                name="language"
                id="language"
                value={userData?.language}
                onChange={(e) => onChangeHandler(e, "language")}>
                <option value="Select">Select</option>
                <option value="Hindi">Hindi</option>
                <option value="English">English</option>
              </select>
            </div>
          </div>
          <label htmlFor="experience">Experience</label>
          <input
            className={styles.profileArea__etid__input}
            type="number"
            name="experience"
            id="expereience"
            value={userData?.expereience}
            onChange={(e) => onChangeHandler(e, "expereience")}
          />
          {/* <label htmlFor="experience">Social Link</label>
          <input
            className={styles.profileArea__etid__input}
            type="url"
            name="externalLink"
            id="externalLink"
            value={userData?.externalLink}
            placeholder="Facebook, Instagram, Etc."
            onChange={(e) => onChangeHandler(e, "externalLink")}
          /> */}
          <div>
            <label htmlFor="gender">Gender</label>
            <select
              className={styles.profileArea__etid__select}
              name="gender"
              id="gender"
              value={userData?.gender}
              onChange={(e) => onChangeHandler(e, "gender")}>
              <option value="Select">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <label htmlFor="dob">Date of Birth</label>

          <DatePicker
            maxDate={new Date()}
            className={styles.datePicker}
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={onSelectDateHandler}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          <button className={styles.profileArea__etid__btn} onClick={onSaveChangeHandler}>
            {loading ? "Saving..." : "Save Change"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditDet;
