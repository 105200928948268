import React from "react";
import style from "./index.module.scss";

const InputField = (props) => {
  return (
    <>
      <label style={{ display: `${props.display}` }} htmlFor={props.id}>
        {props.label}
      </label>
      <input
        forma
        style={{
          borderRadius: `${props.borderRadius}`,
          height: `${props.height}`,
          border: `${props.border}`,
          marginBottom: `${props.marginBottom}`,
          marginTop: `${props.marginTop}`,
        }}
        className={style.input}
        type={props.type}
        placeholder={props.placeholder}
        id={props.id}
        onChange={(e) => props.onChange(e, props.id)}
        value={props.value}
      />
    </>
  );
};

export default InputField;
