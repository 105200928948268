import React, { useEffect, useState } from "react";
import CustomeBanner from "../../components/CustomeBanner";
import style from "./index.module.scss";
import CourseCard from "../../components/CourseCard";
import PoojaTitle from "./PoojaTitle";
import Modal from "../../modal";
import PanditJiModal from "../../modal/PanditJiModal";
import panditJiBg from "../../assets/panditjibg.jpg";
import axios from "axios";
import Loading from "../../components/Loading";
import noDataImg from "../../assets/noData.png";
import { useNavigate } from "react-router-dom";
import PanditList from "./PanditList";
import Slider from "react-slick";
import { endpoints } from "../../utils/Endpoints";

const BookPanditJi = () => {
  const [showPanditJiModal, setShowPanditJiModal] = useState(false);
  const [poojaDet, setPoojaDet] = useState();
  const [poojaList, setPoojaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [panditList, setPanditList] = useState([]);
  const navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1604,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1404,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 868,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const openBookPanditJiModal = (dta) => {
    // setShowPanditJiModal(true);
    setPoojaDet(dta);
    navigate("/book-pandit-ji/pooja-details");
    localStorage.setItem("poojaDetails", JSON.stringify(dta));
  };

  const hidePanditJiModal = () => {
    setShowPanditJiModal(false);
  };

  useEffect(() => {
    const getPandit = {
      url: endpoints.getPanditList,
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    };
    setLoading(true);
    axios
      .get("https://dev.adhigyanam.com/admin/api/poojaService-get")
      .then((res) => {
        if (res.data.status === true) {
          setPoojaList(res.data.data);
          setSearchData(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // ********************** Get Pandit List ****************

    axios(getPandit)
      .then((res) => {
        console.log(res);
        setPanditList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("finnaly");
      });
    // eslint-disable-next-line
  }, []);

  //******************************************* Search Pujas *********************************************************/
  const onSearchHandler = (e) => {
    let matchData = searchData.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
    setPoojaList(matchData);
  };

  return (
    <>
      {showPanditJiModal && (
        <Modal onClose={hidePanditJiModal} height="auto">
          <PanditJiModal setShowPanditJiModal={setShowPanditJiModal} onCloseHandler={hidePanditJiModal} poojaDet={poojaDet} />
        </Modal>
      )}
      <CustomeBanner
        // height="400px"
        bgImage={panditJiBg}
        title="Book Pandit Ji"
        desc="To book a professional/experienced Pandit online you have to choose the
            puja service and enter your details with us: Full Name, E-mail Address,
            Mobile Number, Date of Puja, Type of puja & Location Address."></CustomeBanner>
      {loading && (
        <div style={{ height: "100px", width: "100%", textAlign: "center" }}>
          <Loading />
        </div>
      )}

      {!loading && (
        <div className={style.container}>
          <div className={style.container__panditList}>
            <Slider className="home_slider" {...settings}>
              {panditList.map((item, index) => {
                return (
                  <PanditList key={index} img={item.profile_pic} name={item.name} service={item.service} experience={item.experience} />
                );
              })}
            </Slider>
          </div>

          <PoojaTitle data={poojaList} tittle="Pooja List" onSearchHandler={onSearchHandler} />
          {poojaList.length > 0 ? (
            <div className={style.container__poojaList}>
              {poojaList.map((item) => {
                return (
                  <CourseCard
                    key={item.id}
                    title={item.name}
                    price={item.price}
                    image={item.icon}
                    btnName="Book Now"
                    height="35px"
                    backgroundColor="$eight-color"
                    marginTop="0"
                    marginBottom="0"
                    textTransform="capitalize"
                    fontSize="16px"
                    display="none"
                    rating={item.rating}
                    onClick={() => openBookPanditJiModal(item)}
                  />
                );
              })}
            </div>
          ) : (
            <div className={style.emptyMsg}>
              <img src={noDataImg} alt="err" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BookPanditJi;
