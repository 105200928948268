import React from "react";
import style from "./index.module.scss";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import CommonTab from "../../components/CommanTab";
import graphology from "../../assets/p7.webp";
import Button from "../../widgets/Button";
import PlanList from "../../components/PlanList";
import { useState } from "react";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import { endpoints } from "../../utils/Endpoints";
import { useEffect } from "react";
import axios from "axios";
import Loading from "../../components/Loading";
import CommanConsultationList from "../../components/CommanConsultationLists";
import noDataFoundImg from "../../assets/noData.png";

const Graphology = () => {
  const [loder, setLoder] = useState(true);
  const [selectedTab, setSeletedTab] = useState("telephonic_consultation");
  const [showLoader, setShowLoader] = useState(true);
  const [graphologyPlans, setGraphologyPlans] = useState([]);
  const [consaltentData, setConsaltentData] = useState([]);
  const [serviceList, setServiceList] = useState({
    telephonic_consultation: [],
    chat_consultation: [],
    video_consultation: [],
    telephonic_consultation_with_report: [],
  });
  const [showBtn, setShowBtn] = useState(false);
  const [selected, setSelected] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const [ctType, setCttype] = useState([]);
  const [price, setPrice] = useState("");

  const [consultationId, setConsultaitonId] = useState(8);

  const [allChecked, setAllchecked] = useState(false);
  const listOfConsultation = JSON.parse(localStorage.getItem("allGraphologyData"));
  const consultationData = {
    asrtoDetail: JSON.parse(localStorage.getItem("astroDet")),
    consultationType: selectedTab,
    consultationList: serviceList[selectedTab],
    consultationTypeId: consultationId,
    planPrice: price,
  };
  const location = useLocation();
  useEffect(() => {
    if (listOfConsultation) {
      serviceList[listOfConsultation?.consultationType] = listOfConsultation?.consultationList;

      setServiceList(serviceList);
    }
    // eslint-disable-next-line
  }, []);

  // ***********************************************
  useEffect(() => {
    const getcsList = {
      url: "https://dev.adhigyanam.com/admin/api/get-consultation-type",
      method: "POST",
      data: {
        predection_id: consultationData.asrtoDetail.servicId,
      },
    };
    axios(getcsList)
      .then((res) => {
        setCttype(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);
  // ****************************************
  useEffect(() => {
    const setPlans = {
      url: endpoints.plans,
      method: "POST",
      data: {
        predection_id: consultationData.asrtoDetail.servicId,
        consultation_id: consultationId,
      },
    };
    const setConsaltentList = {
      url: endpoints.consaltationlist + `/${params.id}`,
      method: "GEt",
    };
    axios(setPlans)
      .then((res) => {
        setGraphologyPlans(res.data.data);
        setShowLoader(false);
      })
      .catch((err) => console.log(err));
    //****************ConsaltantList************* */
    axios(setConsaltentList)
      .then((res) => {
        setConsaltentData(res.data.data);
        setLoder(false);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [params.id]);

  // ******************** Select List and Plan ******************************
  const onCheckedListHandler = (list) => {
    let allList = { ...serviceList };

    let filterList;
    let findList = allList[selectedTab]?.find((item) => {
      return item === list;
    });
    if (findList) {
      filterList = allList[selectedTab]?.filter((item) => item !== list);
      allList[selectedTab] = filterList;
      setServiceList(allList);
    } else {
      allList[selectedTab] = [...allList[selectedTab], list];
      setServiceList({ ...allList });
    }
  };
  useEffect(() => {
    setSelected(serviceList[selectedTab]?.length);

    if (consultationData?.consultationList?.length) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
    // eslint-disable-next-line
  }, [consultationData.consultationList]);
  const onRedirectHandler = () => {
    const path = generatePath(`/astrology/${params.id}/${params.name}/graphology-details/graphology-form`);
    navigate(path, { state: { formTittle: location?.state?.tittleName } });
    localStorage.setItem("allGraphologyData", JSON.stringify(consultationData));
  };

  const onTabHandler = (name, id) => {
    setConsultaitonId(id);
    setSeletedTab(name);
  };
  const allselect = () => {
    let allList = { ...serviceList };
    let allData = consaltentData.map((item) => item.name);
    if (allList[selectedTab]?.length === allData.length) {
      allList[selectedTab] = [];
      setServiceList(allList);
      setAllchecked(false);
    } else {
      setAllchecked(true);
      allList[selectedTab] = allData;
      setServiceList(allList);
    }
  };
  useEffect(() => {
    let allData = consaltentData.map((item) => item.name);
    if (serviceList[selectedTab]?.length === allData.length) {
      setAllchecked(true);
    } else {
      setAllchecked(false);
    }
    // eslint-disable-next-line
  }, [onCheckedListHandler]);
  // *************************************************RazorPay***************************************
  useEffect(() => {
    const planPrice = graphologyPlans.find(
      (item) => consultationData.consultationList.length >= item.topic && consultationData.consultationList.length <= item.max_topic
    );
    setPrice(planPrice?.price);

    // eslint-disable-next-line
  }, [serviceList]);
  // **************************************************************************************************
  return (
    <div className={style.graphology}>
      <div className={style.graphology__top}>
        <div className={style.graphology__top__left}>
          <CommanTitleWithLogo width="40%" tittle={params.name} icon={graphology} />
          <CommonTab marginBottom="0" items={ctType} onClick={onTabHandler} />
        </div>
        <div className={style.graphology__top__right}>
          {showBtn && (
            <Button marginTop="0" btnName="Proceed" fontSize="18px" width="120px" textTransform="capitalize" onClick={onRedirectHandler} />
          )}
        </div>
      </div>
      {loder ? (
        <Loading />
      ) : (
        <div className={style.graphology__bottom}>
          <div className={style.graphology__bottom__left}>
            <CommanConsultationList
              containerWidth="100%"
              width="200px"
              allChecked={allChecked}
              allselect={allselect}
              items={serviceList[selectedTab]}
              onClick={onCheckedListHandler}
              lists={consaltentData}
              image={graphology}
            />
          </div>
          <div className={style.graphology__bottom__right}>
            {showLoader ? (
              <Loading />
            ) : (
              <>
                {graphologyPlans.length <= 0 ? (
                  <span className={style.emptyMsg}>
                    <img src={noDataFoundImg} alt="err" />
                  </span>
                ) : (
                  <>
                    {" "}
                    {graphologyPlans.map((plan) => (
                      <PlanList
                        selected={selected >= parseInt(plan.topic) && selected <= parseInt(plan.max_topic)}
                        fontSize="18px"
                        marginBottom="20px"
                        key={plan.id}
                        plan={plan.description}
                        planNo={plan.name}
                      />
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Graphology;
