import React, { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import kundliImg from "../../assets/p9.webp";
import KundliCard from "./kundliCard";
import KundliTab from "./KundliTab";
import Modal from "../../modal";
import CommonInputField from "../../widgets/CommonInputField";
import Button from "../../widgets/Button";
import CommonSelect from "../../components/CommonSelect";
import DatePicker from "react-datepicker";
import axios from "axios";
import swal from "sweetalert";
import ModalLayout from "../../components/ModalLayout";
const FreeKundli = () => {
  const tabList = [
    {
      id: 1,
      name: "Dosha",
      key: "Dosha",
    },
    {
      id: 2,
      name: "Dasha",
      key: "Dasha",
    },
    {
      id: 3,
      name: "Horoscope",
      key: "Horoscope",
    },
    // {
    //   id: 4,
    //   name: "Matching",
    //   key: "Matching",
    // },
    // {
    //   id: 5,
    //   name: "Panchang",
    //   key: "Panchang",
    // },
    // {
    //   id: 6,
    //   name: "Prediction",
    //   key: "Prediction",
    // },
  ];
  const dosha = [
    {
      id: 1,
      name: "Mangal Dosh",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dosha",
      subKey: "mangal-dosh",
    },
    {
      id: 2,
      name: "Kaalsarp Dosh",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dosha",
      subKey: "kaalsarp-dosh",
    },
    {
      id: 3,
      name: "Manglik Dosh",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dosha",
      subKey: "manglik-dosh",
    },
    {
      id: 4,
      name: "Pitra Dosha",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dosha",
      subKey: "pitra-dosh",
    },
    {
      id: 5,
      name: "Papasamaya",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dosha",
      subKey: "papasamaya",
    },
  ];
  const dasha = [
    {
      id: 1,
      name: "Mahadasha",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dasha",
      subKey: "maha-dasha",
    },
    {
      id: 2,
      name: "Mahadasha Predictions",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dasha",
      subKey: "maha-dasha-predictions",
    },
    {
      id: 3,
      name: "Antardasha",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dasha",
      subKey: "antar-dasha",
    },
    {
      id: 4,
      name: "Char Dasha Current",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dasha",
      subKey: "char-dasha-current",
    },
    {
      id: 5,
      name: "Char Dasha Main",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dasha",
      subKey: "char-dasha-main",
    },
    {
      id: 6,
      name: "Char Dasha Sub",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dasha",
      subKey: "char-dasha-sub",
    },
    {
      id: 7,
      name: "Current MahaDasha Full",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dasha",
      subKey: "current-mahadasha-full",
    },
    {
      id: 8,
      name: "Current Mahadasha",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dasha",
      subKey: "current-mahadasha",
    },
    {
      id: 9,
      name: "Paryantar Dasha",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dasha",
      subKey: "paryantar-dasha",
    },
    // {
    //   id: 10,
    //   name: "SpecificDasha",
    //   icon: kundliImg,
    //   description: "Planetary position and your chart",
    //   key: "dasha",
    //   subKey: "specific-sub-dasha",
    // },
    {
      id: 11,
      name: "Yogini Dasha Main",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dasha",
      subKey: "yogini-dasha-main",
    },
    {
      id: 12,
      name: "Yogini Dasha Sub",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "dasha",
      subKey: "yogini-dasha-sub",
    },
  ];
  const horoscope = [
    {
      id: 1,
      name: "Planet Details",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "horoscope",
      subKey: "planet-details",
    },
    {
      id: 2,
      name: "Ascendant Report",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "horoscope",
      subKey: "ascendant-report",
    },
    {
      id: 3,
      name: "Planet Report",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "horoscope",
      subKey: "planet-report",
    },
    {
      id: 4,
      name: "Personal Characteristics",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "horoscope",
      subKey: "personal-characteristics",
    },
    // {
    //   id: 5,
    //   name: "Divisional Charts",
    //   icon: kundliImg,
    //   description: "Planetary position and your chart",
    //   key: "horoscope",
    //   subKey: "divisional-charts",
    // },
    // {
    //   id: 6,
    //   name: "Chart Image",
    //   icon: kundliImg,
    //   description: "Planetary position and your chart",
    //   key: "horoscope",
    //   subKey: "chart-image",
    // },
    {
      id: 7,
      name: "Ashtakvarga",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "horoscope",
      subKey: "ashtakvarga",
    },
    {
      id: 8,
      name: "Binnashtakvarga",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "horoscope",
      subKey: "binnashtakvarga",
    },
    {
      id: 9,
      name: "Western Planets",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "horoscope",
      subKey: "western-planets",
    },
  ];
  const matching = [
    {
      id: 1,
      name: "North Match",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "ashtakoot",
    },
    {
      id: 2,
      name: "North Match with Astro Details",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "ashtakoot-with-astro-details",
    },
    {
      id: 3,
      name: "South Match",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "dashakoot",
    },
    {
      id: 4,
      name: "South Match with Astro details Copy",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "dashakoot-with-astro-details",
    },
    {
      id: 5,
      name: "Aggregate Match",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "aggregate-match",
    },
    {
      id: 6,
      name: "Rajju Vedha Match",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "rajju-vedha-details",
    },
    {
      id: 7,
      name: "Papasamaya Match",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "papasamaya-match",
    },
    {
      id: 8,
      name: "Nakshatra Match",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "nakshatra-match",
    },
    {
      id: 9,
      name: "Western Match",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "western-match",
    },
    {
      id: 10,
      name: "Bulk North Match",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "bulk-north-match",
    },
    {
      id: 11,
      name: "Bulk South Match",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "bulk-south-match",
    },
    {
      id: 12,
      name: "Bulk West Match",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "matching",
      subKey: "bulk-west-match",
    },
  ];
  const panchang = [
    {
      id: 1,
      name: "Panchang",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "panchang",
    },
    {
      id: 2,
      name: "Monthly Panchang",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "monthly-panchang",
    },
    {
      id: 3,
      name: "Choghadiya Muhurta",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "choghadiya-muhurta",
    },
    {
      id: 4,
      name: "Hora Muhurta",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "hora-muhurta",
    },
    {
      id: 5,
      name: "Moon Calendar",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "moon-calendar",
    },
    {
      id: 6,
      name: "Moon Phase",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "moon-phase",
    },
    {
      id: 7,
      name: "Moon Rise",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "moonrise",
    },
    {
      id: 8,
      name: "Moon Set",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "moonset",
    },
    {
      id: 9,
      name: "Solar Noon",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "solarnoon",
    },
    {
      id: 10,
      name: "Sun Rise",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "sunrise",
    },
    {
      id: 11,
      name: "Sun Set",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "sunset",
    },
    {
      id: 12,
      name: "Retrogrades",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "panchang",
      subKey: "retrogrades",
    },
  ];
  const prediction = [
    {
      id: 1,
      name: "Daily Sun",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "prediction",
    },
    {
      id: 2,
      name: "Daily Nakshatra",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "prediction",
    },
    {
      id: 3,
      name: "Daily Moon",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "prediction",
    },
    {
      id: 4,
      name: "Weekly Moon",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "prediction",
    },
    {
      id: 5,
      name: "Weekly Sun",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "prediction",
    },
    {
      id: 6,
      name: "Yearly",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "prediction",
    },
    {
      id: 7,
      name: "BioRhythm",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "prediction",
    },
    {
      id: 6,
      name: "Day Number",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "prediction",
    },
    {
      id: 9,
      name: "Numerology",
      icon: kundliImg,
      description: "Planetary position and your chart",
      key: "prediction",
    },
  ];
  const languageList = [
    // { id: 1, name: "Select" },
    { id: 2, name: "Hindi" },
    { id: 3, name: "English" },
  ];
  const planetList = [
    { id: 1, name: "Jupiter" },
    { id: 1, name: "Saturn" },
    { id: 1, name: "Mercury" },
    { id: 1, name: "Ketu" },
    { id: 1, name: "Venus" },
    { id: 1, name: "Sun" },
    { id: 1, name: "Moon" },
    { id: 1, name: "Mars" },
    { id: 1, name: "Rahu" },
    { id: 1, name: "Ascendant" },
  ];
  //   ***************** Objects ************************
  const [tab, setTab] = useState(dosha);
  const [showForm, setShowForm] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [apiType, setApiType] = useState("dasa");
  const [tittle, setTittle] = useState("");
  const [subApitype, setSubApitype] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [apiData, setApiData] = useState([]);
  // const [horoscopData, setHoroscopData] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
    place_of_birth: "",
    time_of_birth: "",
    Language: languageList[0].name,
    Planet: planetList[0].name,
  });

  const onGetKundliHandler = (keyname, subKey, name) => {
    setShowForm(true);
    setApiType(keyname);
    setSubApitype(subKey);
    setTittle(name);
  };
  const onTabHandler = (key) => {
    switch (key) {
      case "Dasha":
        setTab(dasha);
        break;
      case "Dosha":
        setTab(dosha);
        break;
      case "Horoscope":
        setTab(horoscope);
        break;
      case "Matching":
        setTab(matching);
        break;
      case "Panchang":
        setTab(panchang);
        break;
      case "Prediction":
        setTab(prediction);
        break;
      default:
        setTab(dosha);
        break;
    }
    // setTab(key);
  };
  // ****************************** Check Dosha *******************************
  // navigator.geolocation.getCurrentPosition((position) => {
  //   const lat = position.coords.latitude;
  //   const long = position.coords.longitude;

  // });

  useEffect(() => {
    // **************************Get Lag Long**********************

    let API_KEY = "AIzaSyCOPgsFUVkC4LJFYXDrGwjhNORZLDYX3Fs";

    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${userData.place_of_birth}&key=${API_KEY}`;
    axios
      .get(url)
      .then((res) => {
        setLatitude(res.data.results[0].geometry?.location?.lat);
        setLongitude(res.data.results[0].geometry?.location?.lng);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userData.place_of_birth]);

  // *****************************************************
  const onChangeHandler = (e, id) => {
    setUserData({
      ...userData,
      [id]: e.target.value,
    });
  };
  const onCloseModal = () => {
    setShowForm(false);
    setShowDetail(false);
  };
  const onSelectDateHandler = (date) => {
    setStartDate(date);
    let userDob = new Date(date);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${sday}/${smonth}/${year}`);
  };
  useEffect(() => {
    let userDob = new Date(startDate);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${sday}/${smonth}/${year}`);

    // eslint-disable-next-line
  }, []);

  const onSubmitHandler = () => {
    // debugger;
    let API_KEY = "df8acaf2-fe44-5f43-ae9f-fc5b10f15d71";
    let url;
    switch (apiType) {
      case "dasha":
        url = `https://api.vedicastroapi.com/v3-json/dashas/${subApitype}?dob=${selectedDate}&tob=${
          userData.time_of_birth
        }&lat=${latitude}&lon=${longitude}&tz=5.5&api_key=${API_KEY}&lang=${
          userData.Language === "Hindi" ? "hi" : "en"
        }`;
        break;
      case "dosha":
        url = `https://api.vedicastroapi.com/v3-json/${apiType}/${subApitype}?dob=${selectedDate}&tob=${
          userData.time_of_birth
        }&lat=${latitude}&lon=${longitude}&tz=5.5&api_key=${API_KEY}&lang=${
          userData.Language === "Hindi" ? "hi" : "en"
        }`;
        break;
      case "horoscope":
        if (subApitype === "planet-report") {
          url = `https://api.vedicastroapi.com/v3-json/${apiType}/${subApitype}?dob=${selectedDate}&tob=${
            userData.time_of_birth
          }&lat=${latitude}&lon=${longitude}&tz=5.5&api_key=${API_KEY}&planet=${
            userData.Planet
          }&lang=${userData.Language === "Hindi" ? "hi" : "en"}`;
        } else if (subApitype === "binnashtakvarga") {
          url = `https://api.vedicastroapi.com/v3-json/horoscope/binnashtakvarga?dob=${selectedDate}&tob=${
            userData.time_of_birth
          }&lat=${latitude}&lon=${longitude}&tz=5.5&api_key=${API_KEY}&lang=${
            userData.Language === "Hindi" ? "hi" : "en"
          }&planet=${userData.Planet}`;
        } else {
          url = `https://api.vedicastroapi.com/v3-json/${apiType}/${subApitype}?dob=${selectedDate}&tob=${
            userData.time_of_birth
          }&lat=${latitude}&lon=${longitude}&tz=5.5&api_key=${API_KEY}&lang=${
            userData.Language === "Hindi" ? "hi" : "en"
          }`;
        }
        break;
      case "matching":
        setTab(matching);
        break;
      case "panchang":
        url = `https://api.vedicastroapi.com/v3-json/${apiType}/${subApitype}?api_key=${API_KEY}&date=${selectedDate}&tz=5.5&lat=${latitude}&lon=${longitude}&time=${
          userData.time_of_birth
        }&lang=${userData.Language === "Hindi" ? "hi" : "en"}`;

        break;
      case "prediction":
        setTab(prediction);
        break;
      default:
        setTab(dosha);
        break;
    }

    if (userData.name === "") {
      swal("Please Enter Name", "Please Enter Name", "error");
    } else if (userData.place_of_birth === "") {
      swal(
        "Please Enter Place of Birth",
        "Please Enter Place of Birth",
        "error"
      );
    } else if (userData.time_of_birth === "") {
      swal("Please Enter Time of Birth", "Please Enter Time of Birth", "error");
    } else if (userData.Language === "Select") {
      swal("Please Select Language", "Please Select Language", "error");
    } else {
      axios
        .get(url)
        .then((res) => {
          setApiData(res.data.response);
          setShowDetail(true);
          setShowForm(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      {showForm && (
        <Modal onClose={onCloseModal} overflowY="hidden">
          <ModalLayout onCloseHandler={onCloseModal}>
            <div className={Styles.formArea}>
              <h1>Fill Some Details</h1>
              <div className={Styles.formArea__fieldArea}>
                <CommonInputField
                  onChange={(e) => onChangeHandler(e, "name")}
                  borderColor="#1eb2a6"
                  margin="5px 0"
                  display="none"
                  value={userData.name}
                  placeholder="Name"
                  type="text"
                />
                <div className={Styles.formArea__fieldArea__select}>
                  <p>Date of Birth</p>
                  <DatePicker
                    maxDate={new Date()}
                    className={Styles.datePicker}
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={onSelectDateHandler}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
                <CommonInputField
                  borderColor="#1eb2a6"
                  margin="5px 0"
                  value={userData.time_of_birth}
                  placeholder="Time Of Birth"
                  type="time"
                  onChange={(e) => onChangeHandler(e, "time_of_birth")}
                />
                <CommonSelect
                  margin="5px 0"
                  marginTop="5px"
                  value={userData.Language}
                  label="Language"
                  list={languageList}
                  onChange={onChangeHandler}
                />
                <CommonInputField
                  borderColor="#1eb2a6"
                  margin="5px 0"
                  display="none"
                  value={userData.place_of_birth}
                  placeholder="Place Of Birth"
                  type="text"
                  onChange={(e) => onChangeHandler(e, "place_of_birth")}
                />
                {(subApitype === "planet-report" ||
                  subApitype === "binnashtakvarga") && (
                  <CommonSelect
                    margin="5px 0"
                    marginTop="5px"
                    value={userData.Planet}
                    label="Language"
                    list={planetList}
                    onChange={onChangeHandler}
                  />
                )}
              </div>
              <Button btnName="Submit" onClick={onSubmitHandler} />
            </div>
          </ModalLayout>
        </Modal>
      )}
      {showDetail && (
        <Modal onClose={onCloseModal} overflowY="auto">
          <ModalLayout onCloseHandler={onCloseModal}>
            <div className={Styles.apiDetai}>
              <h1>* {tittle} *</h1>
              <>
                {/* {apiType === "horoscope" && <span className={Styles.apiDetai__coming}>Comming Soon!</span>} */}
                {subApitype === "mangal-dosh" && (
                  <>
                    <p>{apiData?.factors?.moon}</p>
                    <p>{apiData?.factors?.saturn}</p>
                    <p>{apiData?.factors?.rahu}</p>
                    <p>{apiData?.factors?.mars}</p>
                    <h4>{apiData?.bot_response}</h4>
                  </>
                )}
                {subApitype === "kaalsarp-dosh" && (
                  <>
                    <h4>{apiData?.bot_response}</h4>
                    {apiData?.remedies?.map((item) => {
                      return <p>{item}</p>;
                    })}
                  </>
                )}
                {subApitype === "manglik-dosh" && (
                  <>
                    <h4>{apiData?.bot_response}</h4>
                    {apiData?.factors?.map((item) => {
                      return <p>{item}</p>;
                    })}
                  </>
                )}
                {subApitype === "pitra-dosh" && (
                  <>
                    <h4>{apiData?.bot_response}</h4>
                    {apiData?.effects?.map((item) => {
                      return <p>{item}</p>;
                    })}
                    {apiData?.remedies?.map((item) => {
                      return <p>{item}</p>;
                    })}
                  </>
                )}
                {subApitype === "papasamaya" && (
                  <>
                    <p>Rahu : {apiData?.rahu_papa}</p>
                    <p>Sun : {apiData?.sun_papa}</p>
                    <p>Saturn : {apiData?.saturn_papa}</p>
                    <h4>Mars : {apiData?.mars_papa}</h4>
                  </>
                )}
                {subApitype === "maha-dasha" && (
                  <>
                    <h4>Dasha Start Date : {apiData?.dasha_start_date}</h4>
                    <h4>
                      Dasha Remaining at Birth :{" "}
                      {apiData?.dasha_remaining_at_birth}
                    </h4>
                    <div className={Styles.apiDetai__mahaDasa}>
                      <div>
                        <h4>Mahadasha</h4>
                        {apiData?.mahadasha?.map((item) => {
                          return <p>{item}</p>;
                        })}
                      </div>
                      <div>
                        <h4>Mahadasha Order</h4>
                        {apiData?.mahadasha_order?.map((item) => {
                          return <p>{item}</p>;
                        })}
                      </div>
                    </div>
                  </>
                )}
                {subApitype === "antar-dasha" && (
                  <>
                    <h4>{apiData?.bot_response}</h4>
                    <div className={Styles.apiDetai__antardasha}>
                      {apiData?.antardashas?.map((item) => {
                        return (
                          <ul>
                            {item.map((item2) => {
                              return <li>{item2}</li>;
                            })}
                          </ul>
                        );
                      })}
                    </div>
                  </>
                )}
                {subApitype === "maha-dasha-predictions" && (
                  <>
                    <div className={Styles.apiDetai__dasapridection}>
                      <h4>{apiData?.translated_mahadasha}</h4>
                      {apiData?.dashas?.map((item) => {
                        return (
                          <ul>
                            <li>
                              <span>prediction</span> : {item.prediction}
                            </li>
                            <li>
                              <span>dasha</span> : {item.dasha}
                            </li>
                            <li>
                              <span>dasha_end_year</span> :{" "}
                              {item.dasha_end_year}
                            </li>
                            <li>
                              <span>dasha_start_year</span> :{" "}
                              {item.dasha_start_year}
                            </li>
                            <li>
                              <span>dasha_start_year</span> :{" "}
                              {item.planet_in_zodiac}
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </>
                )}
                {subApitype === "char-dasha-current" && (
                  <>
                    <h4>Dasha Start Date : {apiData?.main_dasha}</h4>
                    <h4>
                      Dasha Remaining at Birth : {apiData?.main_dasha_lord}
                    </h4>
                    <div className={Styles.apiDetai__mahaDasa}>
                      <div>
                        <h4>sub_dasha_list</h4>
                        {apiData[0]?.sub_dasha_list?.map((item) => {
                          return <p>{item}</p>;
                        })}
                      </div>
                      <div>
                        <h4>sub_dasha_end_dates</h4>
                        {apiData[0]?.sub_dasha_end_dates?.map((item) => {
                          return <p>{item}</p>;
                        })}
                      </div>
                    </div>
                  </>
                )}
                {subApitype === "char-dasha-main" && (
                  <>
                    <div className={Styles.apiDetai__mahaDasa}>
                      <div>
                        <h4>dasha_list</h4>
                        {apiData?.dasha_list?.map((item) => {
                          return <p>{item}</p>;
                        })}
                      </div>
                      <div>
                        <h4>dasha_end_dates</h4>
                        {apiData?.dasha_end_dates?.map((item) => {
                          return <p>{item}</p>;
                        })}
                      </div>
                    </div>
                  </>
                )}

                {subApitype === "char-dasha-sub" && (
                  <>
                    <h4>{apiData?.bot_response}</h4>
                    {apiData?.map((item) => {
                      return (
                        <>
                          <h4>Dasha Start Date : {item?.main_dasha}</h4>
                          <h4>
                            Dasha Remaining at Birth : {item?.main_dasha_lord}
                          </h4>
                          <div className={Styles.apiDetai__mahaDasa}>
                            <div>
                              <h4>sub_dasha_list</h4>
                              {item?.sub_dasha_list?.map((item2) => {
                                return <p>{item2}</p>;
                              })}
                            </div>
                            <div>
                              <h4>sub_dasha_end_dates</h4>
                              {item?.sub_dasha_end_dates?.map((item2) => {
                                return <p>{item2}</p>;
                              })}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
                {subApitype === "current-mahadasha-full" && (
                  <>
                    <>
                      <div className={Styles.apiDetai__mahaDasa}>
                        <div>
                          <h4>Mahadasha</h4>
                          {apiData?.mahadasha?.map((item) => {
                            return (
                              <ul>
                                <li>Name : {item.name}</li>
                                <li>Start : {item.start}</li>
                                <li>End : {item.end}</li>
                              </ul>
                            );
                          })}
                        </div>
                        <div>
                          <h4>Antardasha</h4>

                          {apiData?.antardasha?.map((item) => {
                            return (
                              <ul>
                                <li>Name : {item.name}</li>
                                <li>Start : {item.start}</li>
                                <li>End : {item.end}</li>
                              </ul>
                            );
                          })}
                        </div>
                        <div>
                          <h4>Paryantardasha</h4>

                          {apiData?.paryantardasha?.map((item) => {
                            return (
                              <ul>
                                <li>Name : {item.name}</li>
                                <li>Start : {item.start}</li>
                                <li>End : {item.end}</li>
                              </ul>
                            );
                          })}
                        </div>
                        <div>
                          <h4>Shookshamadasha</h4>

                          {apiData?.Shookshamadasha?.map((item) => {
                            return (
                              <ul>
                                <li>Name : {item.name}</li>
                                <li>Start : {item.start}</li>
                                <li>End : {item.end}</li>
                              </ul>
                            );
                          })}
                        </div>
                        <div>
                          <h4>Pranadasha</h4>
                          {apiData?.Pranadasha?.map((item) => {
                            return (
                              <ul>
                                <li>Name : {item.name}</li>
                                <li>Start : {item.start}</li>
                                <li>End : {item.end}</li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  </>
                )}

                {subApitype === "current-mahadasha" && (
                  <>
                    <>
                      <h4>Mahadasha</h4>

                      <ul>
                        <li>Name : {apiData?.mahadasha?.name}</li>
                        <li>Start : {apiData?.mahadasha?.start}</li>
                        <li>End : {apiData?.mahadasha?.end}</li>
                      </ul>

                      <h4>Antardasha</h4>

                      <ul>
                        <li>Name : {apiData?.antardasha?.name}</li>
                        <li>Start : {apiData?.antardasha?.start}</li>
                        <li>End : {apiData?.antardasha?.end}</li>
                      </ul>

                      <h4>Paryantardasha</h4>

                      <ul>
                        <li>Name : {apiData?.paryantardasha?.name}</li>
                        <li>Start : {apiData?.paryantardasha?.start}</li>
                        <li>End : {apiData?.paryantardasha?.end}</li>
                      </ul>

                      <h4>Shookshamadasha</h4>

                      <ul>
                        <li>Name : {apiData?.Shookshamadasha?.name}</li>
                        <li>Start : {apiData?.Shookshamadasha?.start}</li>
                        <li>End : {apiData?.Shookshamadasha?.end}</li>
                      </ul>

                      <h4>Pranadasha</h4>

                      <ul>
                        <li>Name : {apiData?.Pranadasha?.name}</li>
                        <li>Start : {apiData?.Pranadasha?.start}</li>
                        <li>End : {apiData?.Pranadasha?.end}</li>
                      </ul>
                    </>
                  </>
                )}

                {subApitype === "paryantar-dasha" && (
                  <>
                    <h4>Paryantar Dasha</h4>
                    <div className={Styles.apiDetai__paryantrList}>
                      {apiData?.paryantardasha?.map((item) => {
                        return (
                          <ul>
                            {item.map((item2) => {
                              return (
                                <li>
                                  {item2.map((item3) => {
                                    return <span>* {item3}</span>;
                                  })}
                                </li>
                              );
                            })}
                          </ul>
                        );
                      })}
                    </div>

                    <div className={Styles.apiDetai__paryantr}>
                      {apiData?.paryantardasha_order?.map((item) => {
                        return (
                          <ul>
                            {item.map((item2) => {
                              return <li>{item2}</li>;
                            })}
                          </ul>
                        );
                      })}
                    </div>
                  </>
                )}
                {subApitype === "yogini-dasha-main" && (
                  <>
                    <div className={Styles.apiDetai__paryantr}>
                      <div>
                        <h4>Yogini Dasha List</h4>
                        <ul>
                          {apiData?.dasha_list?.map((item2) => {
                            return <li>{item2}</li>;
                          })}
                        </ul>
                      </div>
                      <div>
                        <h4>Yogini Dasha End Dates</h4>
                        <ul>
                          {apiData?.dasha_end_dates?.map((item2) => {
                            return <li>{item2}</li>;
                          })}
                        </ul>
                      </div>
                      <div>
                        <h4>Yogini Dasha Lord List</h4>
                        <ul>
                          {apiData?.dasha_lord_list?.map((item2) => {
                            return <li>{item2}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </>
                )}

                {subApitype === "yogini-dasha-sub" && (
                  <>
                    <div className={Styles.apiDetai__paryantr}>
                      {apiData?.map((item) => {
                        return (
                          <ul>
                            <h4>{item.main_dasha}</h4>
                            <h4>{item.main_dasha_lord}</h4>
                            {item?.sub_dasha_list?.map((item2) => {
                              return <li>{item2}</li>;
                            })}
                            {item?.sub_dasha_end_dates?.map((item2) => {
                              return <li>{item2}</li>;
                            })}
                          </ul>
                        );
                      })}
                    </div>
                  </>
                )}
                {subApitype === "planet-details" && (
                  <>
                    <div className={Styles.horoscopDetail}>
                      <ul>
                        <h2>{apiData[0]?.name}</h2>
                        <li>
                          <span>Name : </span>
                          {apiData[0]?.name}
                        </li>
                        <li>
                          <span>Full Name : </span>
                          {apiData[0]?.full_name}
                        </li>
                        <li>
                          <span>Golbel Degree : </span>
                          {apiData[0]?.global_degree}
                        </li>
                        <li>
                          <span>House : </span>
                          {apiData[0]?.house}
                        </li>
                        <li>
                          <span>Local Degree : </span>
                          {apiData[0]?.local_degree}
                        </li>
                        <li>
                          <span>Lord Status : </span>
                          {apiData[0]?.lord_status}
                        </li>
                        <li>
                          <span>Nadshatra : </span>
                          {apiData[0]?.nakshatra}
                        </li>
                        <li>
                          <span>Nakshtra Lord : </span>
                          {apiData[0]?.nakshatra_lord}
                        </li>
                        <li>
                          <span>Nakshtra No. : </span>
                          {apiData[0]?.nakshatra_no}
                        </li>
                        <li>
                          <span>Nakshtra Pada : </span>
                          {apiData[0]?.nakshatra_pada}
                        </li>
                        <li>
                          <span>Rasi No. : </span>
                          {apiData[0]?.rasi_no}
                        </li>
                        <li>
                          <span>Zodiac : </span>
                          {apiData[0]?.zodiac}
                        </li>
                        <li>
                          <span>Zodiac Lord : </span>
                          {apiData[0]?.zodiac_lord}
                        </li>
                      </ul>

                      <ul>
                        <h2>{apiData[1]?.name}</h2>

                        <li>
                          <span>Name : </span>
                          {apiData[1]?.name}
                        </li>
                        <li>
                          <span>Full Name : </span>
                          {apiData[1]?.full_name}
                        </li>
                        <li>
                          <span>Golbel Degree : </span>
                          {apiData[1]?.global_degree}
                        </li>
                        <li>
                          <span>House : </span>
                          {apiData[1]?.house}
                        </li>
                        <li>
                          <span>Local Degree : </span>
                          {apiData[1]?.local_degree}
                        </li>
                        <li>
                          <span>Lord Status : </span>
                          {apiData[1]?.lord_status}
                        </li>
                        <li>
                          <span>Nadshatra : </span>
                          {apiData[1]?.nakshatra}
                        </li>
                        <li>
                          <span>Nakshtra Lord : </span>
                          {apiData[1]?.nakshatra_lord}
                        </li>
                        <li>
                          <span>Nakshtra No. : </span>
                          {apiData[1]?.nakshatra_no}
                        </li>
                        <li>
                          <span>Nakshtra Pada : </span>
                          {apiData[1]?.nakshatra_pada}
                        </li>
                        <li>
                          <span>Rasi No. : </span>
                          {apiData[1]?.rasi_no}
                        </li>
                        <li>
                          <span>Zodiac : </span>
                          {apiData[1]?.zodiac}
                        </li>
                        <li>
                          <span>Zodiac Lord : </span>
                          {apiData[1]?.zodiac_lord}
                        </li>
                      </ul>

                      <ul>
                        <h2>{apiData[2]?.name}</h2>

                        <li>
                          <span>Name : </span>
                          {apiData[2]?.name}
                        </li>
                        <li>
                          <span>Full Name : </span>
                          {apiData[2]?.full_name}
                        </li>
                        <li>
                          <span>Golbel Degree : </span>
                          {apiData[2]?.global_degree}
                        </li>
                        <li>
                          <span>House : </span>
                          {apiData[2]?.house}
                        </li>
                        <li>
                          <span>Local Degree : </span>
                          {apiData[2]?.local_degree}
                        </li>
                        <li>
                          <span>Lord Status : </span>
                          {apiData[2]?.lord_status}
                        </li>
                        <li>
                          <span>Nadshatra : </span>
                          {apiData[2]?.nakshatra}
                        </li>
                        <li>
                          <span>Nakshtra Lord : </span>
                          {apiData[2]?.nakshatra_lord}
                        </li>
                        <li>
                          <span>Nakshtra No. : </span>
                          {apiData[2]?.nakshatra_no}
                        </li>
                        <li>
                          <span>Nakshtra Pada : </span>
                          {apiData[2]?.nakshatra_pada}
                        </li>
                        <li>
                          <span>Rasi No. : </span>
                          {apiData[2]?.rasi_no}
                        </li>
                        <li>
                          <span>Zodiac : </span>
                          {apiData[2]?.zodiac}
                        </li>
                        <li>
                          <span>Zodiac Lord : </span>
                          {apiData[2]?.zodiac_lord}
                        </li>
                      </ul>

                      <ul>
                        <h2>{apiData[3]?.name}</h2>

                        <li>
                          <span>Name : </span>
                          {apiData[3]?.name}
                        </li>
                        <li>
                          <span>Full Name : </span>
                          {apiData[3]?.full_name}
                        </li>
                        <li>
                          <span>Golbel Degree : </span>
                          {apiData[3]?.global_degree}
                        </li>
                        <li>
                          <span>House : </span>
                          {apiData[3]?.house}
                        </li>
                        <li>
                          <span>Local Degree : </span>
                          {apiData[3]?.local_degree}
                        </li>
                        <li>
                          <span>Lord Status : </span>
                          {apiData[3]?.lord_status}
                        </li>
                        <li>
                          <span>Nadshatra : </span>
                          {apiData[3]?.nakshatra}
                        </li>
                        <li>
                          <span>Nakshtra Lord : </span>
                          {apiData[3]?.nakshatra_lord}
                        </li>
                        <li>
                          <span>Nakshtra No. : </span>
                          {apiData[3]?.nakshatra_no}
                        </li>
                        <li>
                          <span>Nakshtra Pada : </span>
                          {apiData[3]?.nakshatra_pada}
                        </li>
                        <li>
                          <span>Rasi No. : </span>
                          {apiData[3]?.rasi_no}
                        </li>
                        <li>
                          <span>Zodiac : </span>
                          {apiData[3]?.zodiac}
                        </li>
                        <li>
                          <span>Zodiac Lord : </span>
                          {apiData[3]?.zodiac_lord}
                        </li>
                      </ul>

                      <ul>
                        <h2>{apiData[4]?.name}</h2>

                        <li>
                          <span>Name : </span>
                          {apiData[4]?.name}
                        </li>
                        <li>
                          <span>Full Name : </span>
                          {apiData[4]?.full_name}
                        </li>
                        <li>
                          <span>Golbel Degree : </span>
                          {apiData[4]?.global_degree}
                        </li>
                        <li>
                          <span>House : </span>
                          {apiData[4]?.house}
                        </li>
                        <li>
                          <span>Local Degree : </span>
                          {apiData[4]?.local_degree}
                        </li>
                        <li>
                          <span>Lord Status : </span>
                          {apiData[4]?.lord_status}
                        </li>
                        <li>
                          <span>Nadshatra : </span>
                          {apiData[4]?.nakshatra}
                        </li>
                        <li>
                          <span>Nakshtra Lord : </span>
                          {apiData[4]?.nakshatra_lord}
                        </li>
                        <li>
                          <span>Nakshtra No. : </span>
                          {apiData[4]?.nakshatra_no}
                        </li>
                        <li>
                          <span>Nakshtra Pada : </span>
                          {apiData[4]?.nakshatra_pada}
                        </li>
                        <li>
                          <span>Rasi No. : </span>
                          {apiData[4]?.rasi_no}
                        </li>
                        <li>
                          <span>Zodiac : </span>
                          {apiData[4]?.zodiac}
                        </li>
                        <li>
                          <span>Zodiac Lord : </span>
                          {apiData[4]?.zodiac_lord}
                        </li>
                      </ul>

                      <ul>
                        <h2>{apiData[5]?.name}</h2>

                        <li>
                          <span>Name : </span>
                          {apiData[5]?.name}
                        </li>
                        <li>
                          <span>Full Name : </span>
                          {apiData[5]?.full_name}
                        </li>
                        <li>
                          <span>Golbel Degree : </span>
                          {apiData[5]?.global_degree}
                        </li>
                        <li>
                          <span>House : </span>
                          {apiData[5]?.house}
                        </li>
                        <li>
                          <span>Local Degree : </span>
                          {apiData[5]?.local_degree}
                        </li>
                        <li>
                          <span>Lord Status : </span>
                          {apiData[5]?.lord_status}
                        </li>
                        <li>
                          <span>Nadshatra : </span>
                          {apiData[5]?.nakshatra}
                        </li>
                        <li>
                          <span>Nakshtra Lord : </span>
                          {apiData[5]?.nakshatra_lord}
                        </li>
                        <li>
                          <span>Nakshtra No. : </span>
                          {apiData[5]?.nakshatra_no}
                        </li>
                        <li>
                          <span>Nakshtra Pada : </span>
                          {apiData[5]?.nakshatra_pada}
                        </li>
                        <li>
                          <span>Rasi No. : </span>
                          {apiData[5]?.rasi_no}
                        </li>
                        <li>
                          <span>Zodiac : </span>
                          {apiData[5]?.zodiac}
                        </li>
                        <li>
                          <span>Zodiac Lord : </span>
                          {apiData[5]?.zodiac_lord}
                        </li>
                      </ul>

                      <ul>
                        <h2>{apiData[6]?.name}</h2>

                        <li>
                          <span>Name : </span>
                          {apiData[6]?.name}
                        </li>
                        <li>
                          <span>Full Name : </span>
                          {apiData[6]?.full_name}
                        </li>
                        <li>
                          <span>Golbel Degree : </span>
                          {apiData[6]?.global_degree}
                        </li>
                        <li>
                          <span>House : </span>
                          {apiData[6]?.house}
                        </li>
                        <li>
                          <span>Local Degree : </span>
                          {apiData[6]?.local_degree}
                        </li>
                        <li>
                          <span>Lord Status : </span>
                          {apiData[6]?.lord_status}
                        </li>
                        <li>
                          <span>Nadshatra : </span>
                          {apiData[6]?.nakshatra}
                        </li>
                        <li>
                          <span>Nakshtra Lord : </span>
                          {apiData[6]?.nakshatra_lord}
                        </li>
                        <li>
                          <span>Nakshtra No. : </span>
                          {apiData[6]?.nakshatra_no}
                        </li>
                        <li>
                          <span>Nakshtra Pada : </span>
                          {apiData[6]?.nakshatra_pada}
                        </li>
                        <li>
                          <span>Rasi No. : </span>
                          {apiData[6]?.rasi_no}
                        </li>
                        <li>
                          <span>Zodiac : </span>
                          {apiData[6]?.zodiac}
                        </li>
                        <li>
                          <span>Zodiac Lord : </span>
                          {apiData[6]?.zodiac_lord}
                        </li>
                      </ul>

                      <ul>
                        <h2>{apiData[7]?.name}</h2>

                        <li>
                          <span>Name : </span>
                          {apiData[7]?.name}
                        </li>
                        <li>
                          <span>Full Name : </span>
                          {apiData[7]?.full_name}
                        </li>
                        <li>
                          <span>Golbel Degree : </span>
                          {apiData[7]?.global_degree}
                        </li>
                        <li>
                          <span>House : </span>
                          {apiData[7]?.house}
                        </li>
                        <li>
                          <span>Local Degree : </span>
                          {apiData[7]?.local_degree}
                        </li>
                        <li>
                          <span>Lord Status : </span>
                          {apiData[7]?.lord_status}
                        </li>
                        <li>
                          <span>Nadshatra : </span>
                          {apiData[7]?.nakshatra}
                        </li>
                        <li>
                          <span>Nakshtra Lord : </span>
                          {apiData[7]?.nakshatra_lord}
                        </li>
                        <li>
                          <span>Nakshtra No. : </span>
                          {apiData[7]?.nakshatra_no}
                        </li>
                        <li>
                          <span>Nakshtra Pada : </span>
                          {apiData[7]?.nakshatra_pada}
                        </li>
                        <li>
                          <span>Rasi No. : </span>
                          {apiData[7]?.rasi_no}
                        </li>
                        <li>
                          <span>Zodiac : </span>
                          {apiData[7]?.zodiac}
                        </li>
                        <li>
                          <span>Zodiac Lord : </span>
                          {apiData[7]?.zodiac_lord}
                        </li>
                      </ul>

                      <ul>
                        <h2>{apiData[8]?.name}</h2>

                        <li>
                          <span>Name : </span>
                          {apiData[8]?.name}
                        </li>
                        <li>
                          <span>Full Name : </span>
                          {apiData[8]?.full_name}
                        </li>
                        <li>
                          <span>Golbel Degree : </span>
                          {apiData[8]?.global_degree}
                        </li>
                        <li>
                          <span>House : </span>
                          {apiData[8]?.house}
                        </li>
                        <li>
                          <span>Local Degree : </span>
                          {apiData[8]?.local_degree}
                        </li>
                        <li>
                          <span>Lord Status : </span>
                          {apiData[8]?.lord_status}
                        </li>
                        <li>
                          <span>Nadshatra : </span>
                          {apiData[8]?.nakshatra}
                        </li>
                        <li>
                          <span>Nakshtra Lord : </span>
                          {apiData[8]?.nakshatra_lord}
                        </li>
                        <li>
                          <span>Nakshtra No. : </span>
                          {apiData[8]?.nakshatra_no}
                        </li>
                        <li>
                          <span>Nakshtra Pada : </span>
                          {apiData[8]?.nakshatra_pada}
                        </li>
                        <li>
                          <span>Rasi No. : </span>
                          {apiData[8]?.rasi_no}
                        </li>
                        <li>
                          <span>Zodiac : </span>
                          {apiData[8]?.zodiac}
                        </li>
                        <li>
                          <span>Zodiac Lord : </span>
                          {apiData[8]?.zodiac_lord}
                        </li>
                      </ul>

                      <ul>
                        <h2>{apiData[9]?.name}</h2>

                        <li>
                          <span>Name : </span>
                          {apiData[9]?.name}
                        </li>
                        <li>
                          <span>Full Name : </span>
                          {apiData[9]?.full_name}
                        </li>
                        <li>
                          <span>Golbel Degree : </span>
                          {apiData[9]?.global_degree}
                        </li>
                        <li>
                          <span>House : </span>
                          {apiData[9]?.house}
                        </li>
                        <li>
                          <span>Local Degree : </span>
                          {apiData[9]?.local_degree}
                        </li>
                        <li>
                          <span>Lord Status : </span>
                          {apiData[9]?.lord_status}
                        </li>
                        <li>
                          <span>Nadshatra : </span>
                          {apiData[9]?.nakshatra}
                        </li>
                        <li>
                          <span>Nakshtra Lord : </span>
                          {apiData[9]?.nakshatra_lord}
                        </li>
                        <li>
                          <span>Nakshtra No. : </span>
                          {apiData[9]?.nakshatra_no}
                        </li>
                        <li>
                          <span>Nakshtra Pada : </span>
                          {apiData[9]?.nakshatra_pada}
                        </li>
                        <li>
                          <span>Rasi No. : </span>
                          {apiData[9]?.rasi_no}
                        </li>
                        <li>
                          <span>Zodiac : </span>
                          {apiData[9]?.zodiac}
                        </li>
                        <li>
                          <span>Zodiac Lord : </span>
                          {apiData[9]?.zodiac_lord}
                        </li>
                      </ul>
                    </div>
                  </>
                )}

                {subApitype === "ascendant-report" && (
                  <>
                    {apiData.map((item) => {
                      return (
                        <>
                          <div className={Styles.horo2}>
                            <p>
                              <span>Ascendant : </span>
                              {item.ascendant}
                            </p>
                            <p>
                              <span>Ascendant Lord : </span>
                              {item.ascendant_lord}
                            </p>
                            <p>
                              <span>Ascendant Lord House Location : </span>
                              {item.ascendant_lord_house_location}
                            </p>
                            <p>
                              <span>Ascendant Lord Location : </span>
                              {item.ascendant_lord_location}
                            </p>
                            <p>
                              <span>Ascendant Lord Strength : </span>
                              {item.ascendant_lord_strength}
                            </p>
                            <p>
                              <span>Bad Qualities : </span>
                              {item.bad_qualities}
                            </p>
                            <p>
                              <span>Day for Fasting : </span>
                              {item.day_for_fasting}
                            </p>
                            <p>
                              <span>Flagship Qualities : </span>
                              {item.flagship_qualities}
                            </p>
                            <p>
                              <span>Gayatri Mantra : </span>
                              {item.gayatri_mantra}
                            </p>
                            <p>
                              <span>General Prediction : </span>
                              {item.general_prediction}
                            </p>

                            <p>
                              <span>Good Qualities : </span>
                              {item.good_qualities}
                            </p>
                            <p>
                              <span>Lucky Gem : </span>
                              {item.lucky_gem}
                            </p>
                            <p>
                              <span>Personalised Prediction : </span>
                              {item.personalised_prediction}
                            </p>
                            <p>
                              <span>Spirituality Advice : </span>
                              {item.spirituality_advice}
                            </p>
                            <p>
                              <span>Symbol : </span>
                              {item.symbol}
                            </p>
                            <p>
                              <span>Verbal Location : </span>
                              {item.verbal_location}
                            </p>
                            <p>
                              <span>Zodiac Characteristics : </span>
                              {item.zodiac_characteristics}
                            </p>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}

                {subApitype === "planet-report" && (
                  <>
                    {apiData.map((item) => {
                      return (
                        <>
                          <div className={Styles.horo2}>
                            <p>
                              <span>Affliction : </span>
                              {item.affliction}
                            </p>
                            <p>
                              <span>Character keywords negative</span>
                            </p>
                            <ul style={{ paddingLeft: "20px" }}>
                              {item.character_keywords_negative?.map((list) => {
                                return <li>{list}</li>;
                              })}
                            </ul>
                            <p>
                              <span>Character keywords positive</span>
                            </p>
                            <ul style={{ paddingLeft: "20px" }}>
                              {item.character_keywords_positive?.map((list) => {
                                return <li>{list}</li>;
                              })}
                            </ul>
                            <p>
                              <span>Planet Considered : </span>
                              {item.planet_considered}
                            </p>
                            <p>
                              <span>Planet Definitions : </span>
                              {item.planet_definitions}
                            </p>
                            <p>
                              <span>Planet Location : </span>
                              {item.planet_location}
                            </p>
                            <p>
                              <span>Planet native location : </span>
                              {item.planet_native_location}
                            </p>
                            <p>
                              <span>Planet strength : </span>
                              {item.planet_strength}
                            </p>
                            <p>
                              <span>Planet zodiac : </span>
                              {item.planet_zodiac}
                            </p>
                            <p>
                              <span>Flagship planet zodiac prediction : </span>
                              {item.planet_zodiac_prediction}
                            </p>
                            <p>
                              <span>Gayatri Mantra : </span>
                              {item.gayatri_mantra}
                            </p>
                            <p>
                              <span>General Prediction : </span>
                              {item.general_prediction}
                            </p>

                            <p>
                              <span>Good qualities long : </span>
                              {item.qualities_long}
                            </p>
                            <p>
                              <span>Qualities short : </span>
                              {item.qualities_short}
                            </p>
                            <p>
                              <span>Personalised Prediction : </span>
                              {item.personalised_prediction}
                            </p>
                            <p>
                              <span> Zodiac lord : </span>
                              {item.zodiac_lord}
                            </p>
                            <p>
                              <span>Zodiac lord house location : </span>
                              {item.zodiac_lord_house_location}
                            </p>
                            <p>
                              <span>Verbal Location : </span>
                              {item.verbal_location}
                            </p>
                            <p>
                              <span>Zodiac lord location : </span>
                              {item.zodiac_lord_location}
                            </p>
                            <p>
                              <span>Zodiac lord strength : </span>
                              {item.zodiac_lord_strength}
                            </p>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}

                {subApitype === "personal-characteristics" && (
                  <>
                    <div className={Styles.horoscopDetail}>
                      {apiData.map((item) => {
                        return (
                          <ul>
                            <h2>{item.current_house}</h2>
                            <li>
                              <span>Current house : </span>
                              {item.current_house}
                            </li>
                            <li>
                              <span>Current zodiac : </span>
                              {item.current_zodiac}
                            </li>
                            <li>
                              <span>Lord house location : </span>
                              {item.lord_house_location}
                            </li>
                            <li>
                              <span>Lord of zodiac : </span>
                              {item.lord_of_zodiac}
                            </li>
                            <li>
                              <span>Lord strength : </span>
                              {item.lord_strength}
                            </li>
                            <li>
                              <span>Lord zodiac location : </span>
                              {item.lord_zodiac_location}
                            </li>
                            <li>
                              <span>Personalised prediction : </span>
                              {item.personalised_prediction}
                            </li>
                            <li>
                              <span>Verbal location : </span>
                              {item.verbal_location}
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </>
                )}

                {subApitype === "ashtakvarga" && (
                  <>
                    <div className={Styles.ashtakvarga}>
                      <div>
                        <h3>Ashtakvarga order</h3>
                        <ul>
                          {apiData.ashtakvarga_order.map((item) => {
                            return <li>{item}</li>;
                          })}
                        </ul>
                      </div>
                      <div>
                        <h3>Ashtakvarga points</h3>
                        {apiData.ashtakvarga_points.map((item) => {
                          return (
                            <div>
                              {item.map((list) => {
                                return <span>{list},</span>;
                              })}
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        <h3>Ashtakvarga total</h3>
                        {apiData.ashtakvarga_total.map((list) => {
                          return <p>{list},</p>;
                        })}
                      </div>
                    </div>
                  </>
                )}

                {subApitype === "binnashtakvarga" && (
                  <div className={Styles.binnashtak}>
                    <ul>
                      <h3>Ascedant</h3>
                      {apiData.Ascendant.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                    <ul>
                      <h3>Jupiter</h3>
                      {apiData.Jupiter.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                    <ul>
                      <h3>Mars</h3>
                      {apiData.Mars.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                    <ul>
                      <h3>Mercury</h3>
                      {apiData.Mercury.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                    <ul>
                      <h3>Moon</h3>
                      {apiData.Moon.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                    <ul>
                      <h3>Saturn</h3>
                      {apiData.Saturn.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                    <ul>
                      <h3>Sun</h3>
                      {apiData.Sun.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                    <ul>
                      <h3>Venus</h3>
                      {apiData.Venus.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                  </div>
                )}

                {subApitype === "western-planets" && (
                  <div className={Styles.apiDetai__paryantr}>
                    <div>
                      <>
                        <h3>Ascendant</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.ascendant.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.ascendant.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.ascendant.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.ascendant.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.ascendant.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.ascendant.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Chiron</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.chiron.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.chiron.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.chiron.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.chiron.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.chiron.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.chiron.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Jupiter</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.jupiter.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.jupiter.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.jupiter.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.jupiter.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.jupiter.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.jupiter.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Mars</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.mars.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.mars.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.mars.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.mars.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.mars.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.mars.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Mercury</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.mercury.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.mercury.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.mercury.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.mercury.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.mercury.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.mercury.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Moon</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.moon.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.moon.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.moon.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.moon.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.moon.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.moon.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Neptune</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.neptune.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.neptune.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.neptune.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.neptune.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.neptune.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.neptune.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>North node</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.north_node.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.north_node.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.north_node.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.north_node.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.north_node.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.north_node.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Pluto</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.pluto.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.pluto.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.pluto.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.pluto.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.pluto.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.pluto.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Saturn</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.saturn.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.saturn.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.saturn.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.saturn.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.saturn.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.saturn.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Sirius</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.sirius.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.sirius.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.sirius.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.sirius.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.sirius.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.sirius.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>South node</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.south_node.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.south_node.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.south_node.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.south_node.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.south_node.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.south_node.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Sun</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.sun.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.sun.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.sun.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.sun.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.sun.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.sun.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Uranus</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.uranus.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.uranus.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.uranus.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.uranus.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.uranus.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.uranus.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                    <div>
                      <>
                        <h3>Venus</h3>
                        <ul>
                          <li>
                            <span>Global degree : </span>
                            {apiData.venus.global_degree}
                          </li>
                          <li>
                            <span>House : </span>
                            {apiData.venus.house}
                          </li>
                          <li>
                            <span>Local degree : </span>
                            {apiData.venus.local_degree}
                          </li>
                          <li>
                            <span>Retro : </span>
                            {apiData.venus.retro}
                          </li>
                          <li>
                            <span>Zodiac : </span>
                            {apiData.venus.zodiac}
                          </li>
                          <li>
                            <span>Zodiac_no : </span>
                            {apiData.venus.zodiac_no}
                          </li>
                        </ul>
                      </>
                    </div>
                  </div>
                )}

                {subApitype === "panchang" && (
                  <div className={Styles.panchang}>
                    <div>
                      <div>
                        <h3>Abhijit Muhurat</h3>
                        <p>
                          <span>End :</span>{" "}
                          {apiData.advanced_details.abhijit_muhurta.end}
                        </p>
                        <p>
                          {" "}
                          <span>Start :</span>{" "}
                          {apiData.advanced_details.abhijit_muhurta.start}
                        </p>
                      </div>
                      <h3>Adhargana</h3>
                      <p>{apiData.advanced_details.ahargana}</p>
                      <h3>Disha School</h3>
                      <p>{apiData.advanced_details.disha_shool}</p>
                      <div>
                        <h3>Masa</h3>
                        <p>
                          <span>Adhik Maasa : </span>
                          {apiData.advanced_details.masa.adhik_maasa}
                        </p>
                        <p>
                          <span>Alternate Amanta Name : </span>
                          {apiData.advanced_details.masa.alternate_amanta_name}
                        </p>
                        <p>
                          <span>Alternate Punimanta Name : </span>
                          {
                            apiData.advanced_details.masa
                              .alternate_purnimanta_name
                          }
                        </p>
                        <p>
                          <span>Amanta Date : </span>
                          {apiData.advanced_details.masa.amanta_date}
                        </p>
                        <p>
                          <span>Amanta End : </span>
                          {apiData.advanced_details.masa.amanta_end}
                        </p>
                        <p>
                          <span>Amanta Name : </span>
                          {apiData.advanced_details.masa.amanta_name}
                        </p>
                        <p>
                          <span>Amanta Number : </span>
                          {apiData.advanced_details.masa.amanta_number}
                        </p>
                        <p>
                          <span>Amanta Start : </span>
                          {apiData.advanced_details.masa.amanta_start}
                        </p>
                        <p>
                          <span>Ayana : </span>
                          {apiData.advanced_details.masa.ayana}
                        </p>
                        <p>
                          <span>Moon Phase : </span>
                          {apiData.advanced_details.masa.moon_phase}
                        </p>
                        <p>
                          <span>Paksha : </span>
                          {apiData.advanced_details.masa.paksha}
                        </p>
                        <p>
                          <span>Purnimanta date : </span>
                          {apiData.advanced_details.masa.purnimanta_date}
                        </p>
                        <p>
                          <span>Purnimanta End : </span>
                          {apiData.advanced_details.masa.purnimanta_end}
                        </p>
                        <p>
                          <span>Purnimanta Name : </span>
                          {apiData.advanced_details.masa.purnimanta_name}
                        </p>
                        <p>
                          <span>Purnimanta Number : </span>
                          {apiData.advanced_details.masa.purnimanta_number}
                        </p>
                        <p>
                          <span>Purnimanta Start : </span>
                          {apiData.advanced_details.masa.purnimanta_start}
                        </p>
                        <p>
                          <span>Real Ayana : </span>
                          {apiData.advanced_details.masa.real_ayana}
                        </p>
                        <p>
                          <span>Ritu : </span>
                          {apiData.advanced_details.masa.ritu}
                        </p>
                        <p>
                          <span>Ritu Tamil : </span>
                          {apiData.advanced_details.masa.ritu_tamil}
                        </p>
                      </div>
                      <h3>Moon Rise</h3>
                      <p>{apiData.advanced_details.moon_rise}</p>
                      <h3>Moon Set</h3>
                      <p>{apiData.advanced_details.moon_set}</p>
                      <h3>Moon yogini nivas</h3>
                      <p>{apiData.advanced_details.moon_yogini_nivas}</p>
                      <h3>Next full moon</h3>
                      <p>{apiData.advanced_details.next_full_moon}</p>
                      <h3>Next new moon</h3>
                      <p>{apiData.advanced_details.next_new_moon}</p>
                      <h3>Sun rise</h3>
                      <p>{apiData.advanced_details.sun_rise}</p>
                      <h3>Sun set</h3>
                      <p>{apiData.advanced_details.sun_set}</p>
                      <h3>Vaara</h3>
                      <p>{apiData.advanced_details.vaara}</p>
                      <h3>Ayanamsa</h3>
                      <p>{apiData.ayanamsa.name}</p>
                      <h3>Date</h3>
                      <p>{apiData.date}</p>
                      <h3>Day</h3>
                      <p>{apiData.day.name}</p>
                      <h3>Gulika</h3>
                      <p>{apiData.gulika}</p>
                    </div>
                    <div>
                      <h3>Karana</h3>
                      <p>
                        <span>Diety : </span>
                        {apiData.karana.diety}
                      </p>
                      <p>
                        <span>End : </span>
                        {apiData.karana.end}
                      </p>
                      <p>
                        <span>Lord : </span>
                        {apiData.karana.lord}
                      </p>
                      <p>
                        <span>Name : </span>
                        {apiData.karana.name}
                      </p>
                      <p>
                        <span>Next Karana : </span>
                        {apiData.karana.next_karana}
                      </p>
                      <p>
                        <span>Number : </span>
                        {apiData.karana.number}
                      </p>
                      <p>
                        <span>Special : </span>
                        {apiData.karana.special}
                      </p>
                      <p>
                        <span>Start : </span>
                        {apiData.karana.start}
                      </p>
                      <p>
                        <span>Type : </span>
                        {apiData.karana.type}
                      </p>
                    </div>
                    <div>
                      <h3>Nakshatra</h3>
                      <p>
                        <span>Diety : </span>
                        {apiData.nakshatra.diety}
                      </p>
                      <p>
                        <span>End : </span>
                        {apiData.nakshatra.end}
                      </p>
                      <p>
                        <span>Lord : </span>
                        {apiData.nakshatra.lord}
                      </p>
                      <p>
                        <span>Name : </span>
                        {apiData.nakshatra.name}
                      </p>
                      <p>
                        <span>Next Nakshatra : </span>
                        {apiData.nakshatra.next_nakshatra}
                      </p>
                      <p>
                        <span>Number : </span>
                        {apiData.nakshatra.number}
                      </p>
                      <p>
                        <span>Special : </span>
                        {apiData.nakshatra.special}
                      </p>
                      <p>
                        <span>Start : </span>
                        {apiData.nakshatra.start}
                      </p>
                      <p>
                        <span>Meaning : </span>
                        {apiData.nakshatra.meaning}
                      </p>
                    </div>
                    <div>
                      <h3>Rahukaal</h3>
                      <p>{apiData.rahukaal}</p>
                    </div>
                    <div>
                      <h3>Rasi</h3>
                      <p>
                        <span>Name : </span>
                        {apiData.rasi.name}
                      </p>
                    </div>
                    <div>
                      <h3>Rithi</h3>
                      <p>
                        <span>Diety : </span>
                        {apiData.tithi.diety}
                      </p>
                      <p>
                        <span>End : </span>
                        {apiData.tithi.end}
                      </p>
                      <p>
                        <span>Name : </span>
                        {apiData.tithi.name}
                      </p>
                      <p>
                        <span>Type : </span>
                        {apiData.tithi.type}
                      </p>
                      <p>
                        <span>Number : </span>
                        {apiData.tithi.number}
                      </p>
                      <p>
                        <span>Special : </span>
                        {apiData.tithi.special}
                      </p>
                      <p>
                        <span>Start : </span>
                        {apiData.tithi.start}
                      </p>
                      <p>
                        <span>Meaning : </span>
                        {apiData.tithi.meaning}
                      </p>
                    </div>
                    <div>
                      <h3>Yamakaanta</h3>
                      <p>{apiData.yamakanta}</p>
                    </div>
                    <div>
                      <h3>Yoga</h3>

                      <p>
                        <span>End : </span>
                        {apiData.yoga.end}
                      </p>
                      <p>
                        <span>Name : </span>
                        {apiData.yoga.name}
                      </p>
                      <p>
                        <span>Number : </span>
                        {apiData.yoga.number}
                      </p>
                      <p>
                        <span>Special : </span>
                        {apiData.yoga.special}
                      </p>
                      <p>
                        <span>Start : </span>
                        {apiData.yoga.start}
                      </p>
                      <p>
                        <span>Meaning : </span>
                        {apiData.yoga.meaning}
                      </p>
                      <p>
                        <span>Next Yoga</span>
                        <p>{apiData.yoga.next_yoga}</p>
                      </p>
                    </div>
                  </div>
                )}
              </>

              <div className={Styles.imageArea}>
                <img src={kundliImg} alt="kundli" />
              </div>
            </div>
          </ModalLayout>
        </Modal>
      )}
      <div className={Styles.freekundliArea}>
        <div className={Styles.freekundliArea__tabArea}>
          <div className={Styles.freekundliArea__tabArea__tittle}>
            <CommanTitleWithLogo
              icon={kundliImg}
              tittle="Free Kundli"
              width2="50px"
              height="50px"
            />
          </div>
          <div className={Styles.freekundliArea__tabArea__tab}>
            <KundliTab
              marginBottom="0"
              items={tabList}
              onClick={onTabHandler}
            />
          </div>
        </div>

        <div className={Styles.freekundliArea__kundliType}>
          {tab.map((item, index) => {
            return (
              <KundliCard
                onClick={() =>
                  onGetKundliHandler(item.key, item.subKey, item.name)
                }
                src={item.icon}
                name={item.name}
                description={item.description}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FreeKundli;
