import React, { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import bigImg from "../../assets/banside.webp";
import icon from "../../assets/p7.webp";
import PlanList from "../../components/PlanList";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import CommonTab from "../../components/CommanTab";
import { endpoints } from "../../utils/Endpoints";
import axios from "axios";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../widgets/Button/index";
import Loading from "../../components/Loading";
import CommanConsultationList from "../../components/CommanConsultationLists";
import noDataFoundImg from "../../assets/noData.png";

const PalmReading = () => {
  const [palmPlans, setPalmPlans] = useState([]);
  const [consultationId, setConsultaitonId] = useState(7);
  const [selectedTab, setSeletedTab] = useState("telephonic_consultation");
  const [showLoader, setShowLoader] = useState(true);
  const [consaltentData, setConsaltentData] = useState([]);
  const [showFormbtn, setShowFormbtn] = useState(false);
  const [servicesList, setServicesList] = useState({
    telephonic_consultation: [],
    chat_consultation: [],
    video_consultation: [],
    telephonic_consultation_with_report: [],
  });
  const [select, setSelect] = useState("");
  const [lodding, setLodding] = useState(true);
  const [allChecked, setAllchecked] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [ctType, setCttype] = useState([]);
  const [price, setPrice] = useState("");

  // *******************************************
  const location = useLocation();

  const listOfConsultation = JSON.parse(localStorage.getItem("allConsultationDataForPalm"));

  const consultationData = {
    asrtoDetail: JSON.parse(localStorage.getItem("astroDet")),
    consultationType: selectedTab,
    consultationList: servicesList[selectedTab],
    consultationTypeId: consultationId,
    planPrice: price,
  };

  // ***********************************************
  useEffect(() => {
    const getcsList = {
      url: "https://dev.adhigyanam.com/admin/api/get-consultation-type",
      method: "POST",
      data: {
        predection_id: consultationData.asrtoDetail.servicId,
      },
    };
    axios(getcsList)
      .then((res) => {
        setCttype(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  // *******************************************
  useEffect(() => {
    if (listOfConsultation) {
      servicesList[listOfConsultation?.consultationType] = listOfConsultation?.consultationList;
      setServicesList(servicesList);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const setPlan = {
      url: endpoints.plans,
      method: "POST",
      data: {
        predection_id: consultationData.asrtoDetail.servicId,
        consultation_id: consultationId,
      },
    };
    const setConsaltentList = {
      url: endpoints.consaltationlist + `/${params.id}`,
      method: "GEt",
    };
    axios(setPlan)
      .then((res) => {
        setPalmPlans(res.data.data);
        setShowLoader(false);
      })
      .catch((err) => console.log(err));
    //****************ConsaltantList************* */
    axios(setConsaltentList)
      .then((res) => {
        setConsaltentData(res.data.data);
        setLodding(false);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [params.id]);

  const onSelectListHandler = (list) => {
    let allList = { ...servicesList };

    let filterList;
    let findList = allList[selectedTab]?.find((item) => {
      return item === list;
    });
    if (findList) {
      filterList = allList[selectedTab]?.filter((item) => item !== list);
      allList[selectedTab] = filterList;
      setServicesList(allList);
    } else {
      allList[selectedTab] = [...allList[selectedTab], list];
      setServicesList({ ...allList });
    }
  };
  useEffect(() => {
    setSelect(servicesList[selectedTab]?.length);

    if (consultationData?.consultationList?.length) {
      setShowFormbtn(true);
    } else {
      setShowFormbtn(false);
    }
    // eslint-disable-next-line
  }, [consultationData.consultationList]);
  const onRedirectHandler = () => {
    const path = generatePath(`/astrology/${params.id}/${params.name}/palmistry-details/palm-reading-form`);
    navigate(path, { state: { formTittle: location?.state?.tittleName } });
    localStorage.setItem("allConsultationDataForPalm", JSON.stringify(consultationData));
  };
  const onTabHandler = (name, id) => {
    setConsultaitonId(id);
    setSeletedTab(name);
  };
  const allselect = () => {
    let allList = { ...servicesList };
    let allData = consaltentData.map((item) => item.name);
    if (allList[selectedTab]?.length === allData.length) {
      allList[selectedTab] = [];
      setServicesList(allList);
      setAllchecked(false);
    } else {
      setAllchecked(true);
      allList[selectedTab] = allData;
      setServicesList(allList);
    }
  };
  useEffect(() => {
    let allData = consaltentData.map((item) => item.name);
    if (servicesList[selectedTab]?.length === allData.length) {
      setAllchecked(true);
    } else {
      setAllchecked(false);
    }
    // eslint-disable-next-line
  }, [onSelectListHandler]);

  // *************************************************RazorPay***************************************
  useEffect(() => {
    const planPrice = palmPlans.find(
      (item) => consultationData.consultationList.length >= item.topic && consultationData.consultationList.length <= item.max_topic
    );
    setPrice(planPrice?.price);

    // eslint-disable-next-line
  }, [servicesList]);
  // **************************************************************************************************
  return (
    <div className={Styles.mainContaner}>
      <div className={Styles.mainContaner__tittleArea}>
        <div className={Styles.mainContaner__tittleArea__tittle}>
          <CommanTitleWithLogo tittle={params.name} icon={icon} height="60px" width2="60px" width="300px" />
          <CommonTab items={ctType} marginBottom="0" onClick={onTabHandler} />
        </div>
        <div>
          {showFormbtn && (
            <Button
              backgroundColor="#1eb2a6"
              border="2px solid #1eb2a6"
              color="#fff"
              width="130px"
              fontSize="20px"
              btnName="Proceed"
              marginTop="0"
              onClick={onRedirectHandler}
            />
          )}
        </div>
      </div>

      {lodding ? (
        <Loading />
      ) : (
        <div className={Styles.mainContaner__consultaintArea}>
          <div className={Styles.mainContaner__consultaintArea__consultaintItem}>
            <CommanConsultationList
              containerWidth="100%"
              width="200px"
              allChecked={allChecked}
              allselect={allselect}
              items={servicesList[selectedTab]}
              onClick={onSelectListHandler}
              lists={consaltentData}
              image={bigImg}
            />
          </div>
          <div className={Styles.mainContaner__consultaintArea__plan}>
            {showLoader ? (
              <Loading />
            ) : (
              <>
                {palmPlans.length <= 0 ? (
                  <span className={Styles.emptyMsg}>
                    <img src={noDataFoundImg} alt="err" />
                  </span>
                ) : (
                  <>
                    {palmPlans.map((item, index) => {
                      return (
                        <PlanList
                          selected={select >= parseInt(item.topic) && select <= parseInt(item.max_topic)}
                          key={index}
                          plan={item.description}
                          planNo={item.name}
                          height="52px"
                        />
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PalmReading;
