import React, { useCallback, useContext } from "react";
import styles from "./index.module.scss";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import CommonTab from "../../components/CommanTab";
import icon from "../../assets/p6.webp";
import PlanList from "../../components/PlanList";
import CommonInputField from "../../widgets/CommonInputField";
import DragAndDrop from "../../components/DragAndDrop";
import face from "../../assets/face.webp";
import Button from "../../widgets/Button";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { endpoints } from "../../utils/Endpoints";
import axios from "axios";
import { useEffect } from "react";
import Loading from "../../components/Loading";
import { Context } from "../../utils/ContextStor";
import PhoneNumber from "../../components/PhoneInput";
import CommonSelect from "../../components/CommonSelect";
import Modal from "../../modal";
import BookSlot from "../../modal/BookSlot";
import swal from "sweetalert";
import ConsultationList from "../../components/CommanConsultationLists/ConsultationList";
import SignUp from "../../modal/SignUp";
import Login from "../../modal/Login";
import noDataFoundImg from "../../assets/noData.png";
import { loadScript } from "../../helpers/razorpay";
import Logo from "../../assets/LOGO.webp";
import PaymentOption from "../../components/PaymentOption";
import phonPayimg from "../../assets/PhonePe_Logo.svg.png";
// eslint-disable-next-line
import razorPayimg from "../../assets/Razorpay_payments.png";
import ModalLayout from "../../components/ModalLayout";
import closeImg from "../../assets/close.svg";

const FaceReading = () => {
  const languageList = [
    { id: 1, name: "Select" },
    { id: 2, name: "Hindi" },
    { id: 3, name: "English" },
  ];
  const genderList = [
    { id: 1, name: "Select" },
    { id: 2, name: "Male" },
    { id: 3, name: "Female" },
    { id: 4, name: "Other" },
  ];
  const [signup, setSignup] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [image1, setImage1] = useState("");
  const [document1, setDucument1] = useState();
  const [showDoc, setShowDoc] = useState();
  ///////////
  const [image2, setImage2] = useState("");
  const [document2, setDucument2] = useState();
  const [showDoc2, setShowDoc2] = useState();
  /////////////
  const [image3, setImage3] = useState("");
  const [document3, setDucument3] = useState();
  const [showDoc3, setShowDoc3] = useState();
  ///////////.
  const [phone, setPhone] = useState("");
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [selectedTab, setSeletedTab] = useState("");
// telephonic_consultation
  const [showLoader, setShowLoader] = useState(true);

  const [loder, setLoder] = useState(true);
  const [faceReadingPlans, setFaceReadingPlans] = useState([]);
  const [servicesList, setServicesList] = useState({
    telephonic_consultation: [],
    chat_consultation: [],
    video_consultation: [],
    telephonic_consultation_with_report: [],
  });
  const [select, setSelect] = useState("");
  const [submit, setSubmit] = useState(true);
  const [slotTime, setSlotTime] = useState("Your Slot time");
  const [slotDate, setSlotDate] = useState("Your Slot date");
  const [consultationList, setConsultationList] = useState([]);
  const [ctType, setCttype] = useState([]);
  const [consultationId, setConsultaitonId] = useState(9);
  const [allcheck, setAllcheck] = useState(false);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    Language: languageList[0].name,
    Gender: genderList[0].name,
  });
  // eslint-disable-next-line
  const [token, setToken] = useContext(Context);
  // ********************************Payment Conde*****************************
  const [paymentModal, setPaymentModal] = useState(false);
  const [price, setPrice] = useState("");

  // *************************************************************************
  const params = useParams();
  // eslint-disable-next-line
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  let pathId = JSON.parse(localStorage.getItem("predictionId"));
  const consultationData = {
    asrtoDetail: JSON.parse(localStorage.getItem("astroDet")),
    consultationType: selectedTab,
    consultationList: servicesList[selectedTab],
    consultationTypeId: consultationId,
  };

  const formObj = [
    {
      inputName: "Name",
      inputType: "text",
      plaholder: "Name",
      display: "none",
      value: userData.name,
      id: "name",
    },
    {
      inputName: "gender",
      inputType: "text",
      plaholder: "gender",
      display: "none",
      value: userData.name,
      id: "gender",
    },
    {
      inputName: "Email Id",
      inputType: "email",
      plaholder: "Email Id",
      display: "none",
      value: userData.email,
      id: "email",
    },
  ];
  // ***********************************************
  useEffect(() => {
    const getcsList = {
      url: "https://dev.adhigyanam.com/admin/api/get-consultation-type",
      method: "POST",
      data: {
        predection_id: consultationData.asrtoDetail.servicId,
      },
    };
    axios(getcsList)
      .then((res) => {
        setCttype(res.data.data);
        setSeletedTab(res.data.data[0].name_key)
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  // *******************************************
  useEffect(() => {
    const setPlane = {
      url: endpoints.plans,
      method: "POST",
      data: {
        predection_id: consultationData.asrtoDetail.servicId,
        consultation_id: consultationId,
      },
    };
    const setConsaltentList = {
      url: endpoints.consaltationlist + `/${params.id}`,
      method: "GEt",
    };
    axios(setPlane)
      .then((res) => {
        setFaceReadingPlans(res.data.data);
        setLoder(false);
        setShowLoader(false);
      })
      .catch((err) => console.log(err));
    //****************ConsaltantList************* */
    axios(setConsaltentList)
      .then((res) => {
        setConsultationList(res.data.data);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [params.id, consultationId]);

  const onCheckedListHandler = (e, list) => {
    let allList = { ...servicesList };

    let filterList;
    let findList = allList[selectedTab]?.find((item) => {
      return item === list;
    });
    if (findList) {
      filterList = allList[selectedTab]?.filter((item) => item !== list);
      allList[selectedTab] = filterList;
      setServicesList(allList);
    } else {
      allList[selectedTab] = [...allList[selectedTab], list];
      setServicesList({ ...allList });
    }
  };


    const allselect = () => {
        let allList = { ...servicesList };
        let allData = consultationList.map((item) => item.name);
        if (allList[selectedTab]?.length === allData.length) {
          allList[selectedTab] = [];
          setServicesList(allList);
          setAllcheck(false);
        } else {
          setAllcheck(true);
          allList[selectedTab] = allData;
          setServicesList(allList);
        }
    }


     useEffect(() => {
    let allData = consultationList.map((item) => item.name);
    if (servicesList[selectedTab]?.length !== allData.length) {
      setAllcheck(false);
    } else {
      setAllcheck(true);
    }
    // eslint-disable-next-line
  }, [onCheckedListHandler]);

  const onChangeHandler = (e, id) => {
    setUserData({
      ...userData,
      [id]: e.target.value,
    });
  };
  const filesHandlerOne = (e) => {
    let path = e.target.files[0];
    setImage1(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setDucument1(image);
      setShowDoc(true);
    }
  };
  const filesHandlerTwo = (e) => {
    let path = e.target.files[0];
    setImage2(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setDucument2(image);
      setShowDoc2(true);
    }
  };
  const filesHandlerThree = (e) => {
    let path = e.target.files[0];
    setImage3(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setDucument3(image);
      setShowDoc3(true);
    }
  };
  useEffect(() => {
    setSelect(servicesList[selectedTab]?.length);
    // eslint-disable-next-line
  }, [consultationData.consultationList]);
  // *************************Submit***********************
  const bookingHandler = async (paymentId, paymentOption) => {
    const formData = new FormData();
    formData.append("name", userData.name);
    formData.append("emailid", userData.email);
    formData.append("contact_no", phone);
    formData.append("gender", userData.Gender);
    formData.append("language", userData.Language);
    formData.append("consultation_type", consultationData.consultationType);
    formData.append("consultation_id[]", consultationData.consultationList);
    formData.append("astrologer_id", consultationData.asrtoDetail.astrologerID);
    formData.append("sample1", image1);
    formData.append("sample2", image2);
    formData.append("sample3", image3);
    formData.append("slot_time", slotTime);
    formData.append("slot_date", slotDate);
    formData.append("predection_id", pathId);
    formData.append("consultation_type_id", consultationData.consultationTypeId);
    formData.append("payment_id", paymentId);
    formData.append("pg_type", paymentOption);

    let postData = {
      url: endpoints.faceReadingStor,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };

    axios(postData)
      .then((res) => {
        if (res.data.status === true) {
          window.location.replace(res.data.url);
          swal("Success!", res.data.message, "success");
          setSubmit(true);
        } else {
          swal("Sorry :(", res.data.message);
          setSubmit(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmit(true);
      });
  };

  const onSubmitHandler = async (paymentOption) => {
    // eslint-disable-next-line
    const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    setSubmit(false);

    if (userData.name === "") {
      swal("Failed!", "Name is required", "error");
      setSubmit(true);
    } else if (servicesList[selectedTab]?.length === 0) {
      swal("Failed!", "Please Select Any Topic", "error");
      setSubmit(true);
    } else if (specialChars.test(userData.name)) {
      setSubmit(true);
      swal("Failed!", "Name should not contain special characters", "error");
    } else if (userData.Gender === "Select") {
      swal("Failed!", "Gender is required", "error");
      setSubmit(true);
    } else if (userData.email === "" || !userData.email.includes("@") || !userData.email.includes(".")) {
      swal("Failed!", "Please Enter Valid Email", "error");
      setSubmit(true);
    } else if (phone === "" || phone === undefined) {
      swal("Failed!", "Please Enter Valid Number", "error");
      setSubmit(true);
    } else if (userData.Language === "Select") {
      swal("Failed!", "Language is required", "error");
      setSubmit(true);
    } else if (image1 === "") {
      swal("Failed!", "Image1 is required", "error");
      setSubmit(true);
    } else if (image2 === "") {
      swal("Failed!", "Image2 is required", "error");
      setSubmit(true);
    } else if (image3 === "") {
      swal("Failed!", "Image3 is required", "error");
      setSubmit(true);
    } else if (slotDate === "Your Slot date") {
      swal("Failed!", "Please Select any Slot", "error");
      setSubmit(true);
    } else if (slotTime === "Your Slot time") {
      swal("Failed!", "Please Select any Slot", "error");
      setSubmit(true);
    } else if (paymentOption === "") {
      setPaymentModal(true);
    } else {
      if (user) {
        // **********************************RazorPay**************************************
        if (paymentOption === "razorpay") {
          const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
          if (!res) {
            alert("You are offline ...Failed to load Razorpay SDK", {
              type: "warning",
            });
            return;
          } else {
            const options = {
              key: "rzp_test_gZte4p2li4zBnk",
              currency: "INR",
              amount: price * 100,
              name: "Adhigyanam",
              description: "Thanks for purchasing",
              image: Logo,
              prifill: {
                name: user?.name, //your customer's name
                email: user?.email,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              handler: function (response) {
                if (response) {
                  bookingHandler(response.razorpay_payment_id, paymentOption);
                } else {
                  swal("Failed!", "Payment Failed!", "error");
                }
              },
              // callback_url: "https://dev.adhigyanam.com/admin/razorpay-response",
              // redirect: true,
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        } else {
          bookingHandler("", paymentOption);
        }
      } else {
        swal("Failed!", "Please Login First and Proceed to Pay!", "error");
        setSubmit(true);
        setLoginModal(true);
      }
    }
  };
  const onSelectConsultantType = (name, id) => {
    setConsultaitonId(id);
    setSeletedTab(name);
    setShowLoader(true);
  };
  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };

  const onSelectedSlot = (date, time) => {
    setSlotDate(date);
    setSlotTime(time);
    setModalIsVisible(false);
  };

  const onCloseMoadal = () => {
    setModalIsVisible(false);
    setPaymentModal(false);
  };
  const openCalenderHandler = () => {
    setModalIsVisible(true);
  };
  const onCancelHandler = () => {
    setServicesList({
      telephonic_consultation: [],
      chat_consultation: [],
      video_consultation: [],
      telephonic_consultation_with_report: [],
    });
    setUserData({
      name: "",
      email: "",
      Language: languageList[0].name,
      Gender: genderList[0].name,
    });
    setPhone("");
    setSlotTime("Your Slot time");
    setSlotDate("Your Slot date");
  };
  // **************************Loging************************************
  const onLogin = useCallback(async (userDetails) => {
    setToken(userDetails.token);
    setLoginModal(false);
    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speciality: userDetails.speciality,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.Gender,
      contact_no: userDetails.contact_no,
      language: userDetails.Language,
    };
    localStorage.setItem("token", userDetails.token);
    localStorage.setItem("user", JSON.stringify(filteredData));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);

    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);

  const onSignupHandler = () => {
    setLoginModal(false);
    setSignup(true);
  };
  const onCancelSignUp = () => {
    setLoginModal(true);
    setSignup(false);
  };
  const onCloseModal = () => {
    setLoginModal(false);
    setSignup(false);
  };

  const onClickPaymentOption = (payOption) => {
    setPaymentModal(false);
    onSubmitHandler(payOption);
  };

  // const onShowPaymentModal = () => {
  //   setPaymentModal(true);
  // };
  // *************************************************RazorPay***************************************
  useEffect(() => {
    const planPrice = faceReadingPlans.find(
      (item) => consultationData.consultationList.length >= item.topic && consultationData.consultationList.length <= item.max_topic
    );
    setPrice(planPrice?.price);

    // eslint-disable-next-line
  }, [servicesList]);
  // **************************************************************************************************
  console.log(consultationData.consultationType);
  return (
    <>
      {paymentModal && (
        <ModalLayout onCloseHandler={onCloseMoadal}>
          <Modal onClose={onCloseMoadal} height="auto" overflowY="auto" width="50%">
            <div className={styles.paymentOption}>
              <h1>Choose Payment Option</h1>
              <span className={styles.paymentOption__cros} onClick={onCloseMoadal}>
                <img src={closeImg} alt="close" />
              </span>
              <div className={styles.paymentOption__option}>
                <PaymentOption
                  name="pay"
                  img={phonPayimg}
                  id="phonepay"
                  value="phonepay"
                  onClick={() => onClickPaymentOption("phonepay")}
                />
                {/* <PaymentOption
                  name="pay"
                  img={razorPayimg}
                  id="razorpay"
                  value="razorpay"
                  onClick={() => onClickPaymentOption("razorpay")}
                /> */}
              </div>
            </div>
          </Modal>
        </ModalLayout>
      )}

      {modalIsVisible && (
        <Modal onClose={onCloseMoadal}>
          <BookSlot display="none" width="100%" padding="0" onClick={onSelectedSlot} modalIsVisible={modalIsVisible} />
        </Modal>
      )}
      {loginModal && (
        <Modal onClose={onCloseModal}>
          <Login onSignupHandler={onSignupHandler} onCloseHandler={onCloseModal} onLoginHandler={onLogin} />
        </Modal>
      )}
      {signup && (
        <Modal onClose={onCloseModal}>
          <SignUp setSignup={setSignup} onCloseHandler={onCloseModal} onCancelSignUp={onCancelSignUp} />
        </Modal>
      )}
      <div className={styles.faceReading}>
        <div className={styles.faceReading__tittleArea}>
          <CommanTitleWithLogo tittle={params.name} icon={icon} height="60px" width2="60px" width="300px" />
          <CommonTab onClick={onSelectConsultantType} items={ctType} marginBottom="0" />
        </div>

        {loder ? (
          <Loading />
        ) : (
          <div className={styles.faceReading__content}>
            <div className={styles.faceReading__content__left}>
              <div className={styles.faceReading__content__left__top}>
                <div className={styles.faceReading__content__left__top__topic}>
                  <h2>Topics</h2>
                   <span onClick={allselect} className={styles.faceReading__content__left__top__topic__all}>
                        <input type="checkbox" checked={allcheck} />
                        All
                    </span>
                  {consultationList.map((item, index) => {
                    return (
                      <ConsultationList
                        checked={servicesList[selectedTab]?.includes(item.name)}
                        onClick={(e) => onCheckedListHandler(e, item.name)}
                        key={item.id}
                        id={item.id}
                        lable={item.name}
                      />
                    );
                  })}
                </div>
                <div className={styles.faceReading__content__left__top__form}>
                  <div className={styles.faceReading__content__left__top__form__fields}>
                    {formObj.map((item, index) => {
                      if (item.id === "gender") {
                        return <CommonSelect value={userData.Gender} label="Gender" list={genderList} onChange={onChangeHandler} />;
                      } else {
                        return (
                          <CommonInputField
                            onChange={(e) => onChangeHandler(e, item.id)}
                            key={index}
                            type={item.inputType}
                            placeholder={item.inputName}
                            margin="0"
                            display={item.display}
                            value={item.value}
                          />
                        );
                      }
                    })}
                    <div className={styles.faceReading__content__left__top__form__fields__select}>
                      <p>Enter Phone</p>
                      <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneHandler} />
                    </div>

                    <CommonSelect value={userData.Language} label="Language" list={languageList} onChange={onChangeHandler} />

                    <Button
                      btnName="Choose Slots"
                      marginBottom="0"
                      fontSize="18px"
                      textTransform="capitalize"
                      onClick={openCalenderHandler}
                    />
                    <p className={styles.faceReading__content__left__top__form__fields__select__slot}>{slotTime}</p>
                    <p className={styles.faceReading__content__left__top__form__fields__select__slot}>{slotDate}</p>
                    {/* {paymentOption && (
                      <div className={styles.showPayment}>
                        <p>
                          Payment Option : <span>{paymentOption}</span>{" "}
                        </p>
                        <span>
                          <i onClick={onShowPaymentModal} class="fa-solid fa-pen-to-square"></i>
                        </span>
                      </div>
                    )} */}
                  </div>
                  <div className={styles.faceReading__content__left__top__form__dragDrop}>
                    <DragAndDrop filesHandler={filesHandlerOne} image={document1} showFile={showDoc} height="120px" fontSize="14px" />
                    <DragAndDrop filesHandler={filesHandlerTwo} image={document2} showFile={showDoc2} height="120px" fontSize="14px" />

                    <DragAndDrop filesHandler={filesHandlerThree} image={document3} showFile={showDoc3} height="120px" fontSize="14px" />
                  </div>
                </div>
              </div>
              <div className={styles.faceReading__content__left__bottom}>
                <div className={styles.faceReading__content__left__bottom__face}>
                  <div className={styles.faceReading__content__left__bottom__face__image}>
                    <img src={face} alt="face" />
                  </div>
                  <h3>Sample Upload Images</h3>
                </div>
                <div className={styles.faceReading__content__left__bottom__btn}>
                  <Button
                    btnName="Cancel"
                    backgroundColor="#d4d4d4"
                    color="#444"
                    fontSize="18px"
                    border="1px solid #d4d4d4"
                    width="200px"
                    textTransform="capitalize"
                    onClick={onCancelHandler}
                  />
                  <Button
                    btnName={submit ? "Submit" : "Processing.."}
                    fontSize="18px"
                    width="200px"
                    textTransform="capitalize"
                    onClick={() => onSubmitHandler("")}
                  />
                </div>
              </div>
            </div>
            <div className={styles.faceReading__content__right}>
              <>
                {showLoader ? (
                  <Loading />
                ) : (
                  <>
                    {faceReadingPlans.length <= 0 ? (
                      <span className={styles.emptyMsg}>
                        <img src={noDataFoundImg} alt="err" />
                      </span>
                    ) : (
                      <>
                        {faceReadingPlans.map((item, index) => {
                          return (
                            <PlanList
                              selected={select >= parseInt(item.topic) && select <= parseInt(item.max_topic)}
                              key={index}
                              plan={item.description}
                              planNo={item.name}
                              height="52px"
                              planWidth="auto"
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FaceReading;
