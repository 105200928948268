import React, { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import BookingCard from "../../components/BookingCard";
import CommonTitle from "../../components/CommonTitle";
import axios from "axios";
import noDatafound from "../../assets/noData.png";
import dommyimg from "../../assets/dummyImg.jpg";
import Loading from "../../components/Loading";
const Bookings = () => {
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const details = JSON.parse(localStorage.getItem("user"));

    const formData = new FormData();
    formData.append("astrologer_id", details.id);

    const postData = {
      url: "https://dev.adhigyanam.com/admin/api/booking-get",
      headers: {
        Authorization: `Bearer ${details?.token}`,
      },
      method: "POST",
      data: formData,
    };
    axios(postData)
      .then((res) => {
        if (res.data.status) {
          setBookings(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <div className={Styles.bookingArea}>
      <div className={Styles.bookingArea__navBar}>
        <div className={Styles.bookingArea__navBar__left}>
          <CommonTitle color="#155651" tittle="Booking" width="180px" />
        </div>
      </div>
      <div className={Styles.bookingArea__bookingCardArea}>
        <>
          {loading ? (
            <div className={Styles.loder}>
              <Loading width="100%" />
            </div>
          ) : (
            <>
              {bookings.length === 0 ? (
                <div className={Styles.bookingArea__bookingCardArea__imageArea}>
                  <div className={Styles.bookingArea__bookingCardArea__imageArea__emptyMsg}>
                    <img src={noDatafound} alt="err" />
                  </div>
                </div>
              ) : (
                <>
                  {bookings.map((item, index) => {
                    return (
                      <div key={index} className={Styles.bookingArea__bookingCardArea__card}>
                        <BookingCard
                          imgSrc={item.profile_pic_image ? item.profile_pic_image : dommyimg}
                          name={item.customer_name ? item.customer_name : "N/A"}
                          astroType={item.sub_service_id_name ? item.sub_service_id_name : item.predection_type_name}
                          detail={item.plan_id}
                          time={item.slot_time ? item.slot_time : "N/A"}
                          date={item.slot_date ? item.slot_date : "N/A"}
                          id={item.id}
                          status={item.kundali_status}
                          hasFile={item.has_file}
                          booking_status={item.booking_status}
                          items={item}
                          topics={item?.consultation_topics}
                          sub_service_id={item?.sub_service_id}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default Bookings;
