import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import { Rating } from "@mui/material";
import VideoCard from "./VideoCard";
import ReactPlayer from "react-player";
import axios from "axios";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../widgets/Button";
import logoImg from "../../assets/banside.webp";
import Loading from "../../components/Loading";
import noDataFoundImg from "../../assets/noData.png";
import { loadScript } from "../../helpers/razorpay";
import ModalLayout from "../../components/ModalLayout";
import PaymentOption from "../../components/PaymentOption";
import phonPayimg from "../../assets/PhonePe_Logo.svg.png";
// eslint-disable-next-line
import razorPayimg from "../../assets/Razorpay_payments.png";
import closeImg from "../../assets/close.svg";
import Logo from "../../assets/LOGO.webp";
import Modal from "../../modal";
import swal from "sweetalert";

const PurchaseVideo = () => {
  const [courseDetails, setCourseDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [vLoding, setVLoding] = useState(true);
  const [videos, setVideos] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [title, setTitle] = useState("");
  const [vdescription, setDescription] = useState("");
  const [isProcessing, setIsprocessing] = useState(true);
  const param = useParams();
  const [allPrice, setAllPrice] = useState(parseInt(courseDetails?.price));

  // eslint-disable-next-line
  const [check, setCheck] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));
  let token = user.token;
  let navigate = useNavigate();
  let location = useLocation();
  const [paymentModal, setPaymentModal] = useState(false);
  // *********************************Avoid Right Click**************************************
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  document.onkeydown = function (e) {
    if (e.key === "F12") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "I") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "C") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "J") {
      return false;
    }
    if (e.ctrlKey && e.key === "u") {
      return false;
    }
  };
  //  ******************************* Get Pakage ***************************
  useEffect(() => {
    const formdata = new FormData();
    formdata.append("video_id", courseDetails.id);
    const getVideos = {
      url: "https://dev.adhigyanam.com/admin/api/course-details",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      },
      data: formdata,
    };
    axios(getVideos)
      .then((res) => {
        setVideos(res.data.data);
        setVLoding(false);
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line
  }, [courseDetails]);
  //  ******************************* Get Pakage ***************************
  useEffect(() => {
    const formdata = new FormData();
    formdata.append("package_id", atob(param.id));
    setLoading(true);
    const getConfig = {
      url: "https://dev.adhigyanam.com/admin/api/selected-package-details",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: formdata,
    };

    axios(getConfig)
      .then((res) => {
        if (res.data.status) {
          setCourseDetails(res.data.data);
          setAllPrice(res.data.data.price);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [param.id, token]);

  // const onCheckDoubtPakage = () => {
  //   if (!check) {
  //     setAllPrice(parseInt(allPrice) + parseInt(courseDetails?.doubt_session_fee));
  //     setCheck(true);
  //   } else {
  //     setAllPrice(parseInt(allPrice) - parseInt(courseDetails?.doubt_session_fee));
  //     setCheck(false);
  //   }
  // };
  // *****************************Start Purchase Here*************************
  const bookingHandler = (paymentId, paymentOption) => {
    let formData = new FormData();
    formData.append("package_id", courseDetails.id);
    formData.append("price", allPrice);
    formData.append("is_doubt", check);
    formData.append("payment_id", paymentId);
    formData.append("pg_type", paymentOption);
    let pakageData = {
      url: "https://dev.adhigyanam.com/admin/api/make-course-payment",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    axios(pakageData)
      .then((res) => {
        window.location.replace(res.data.message);
        setIsprocessing(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onPurchasePakageHandler = async (paymentOption) => {
    if (check === true) {
      navigate(`/course/${param.id}/purchase-video/register-doubt-session`, {
        state: {
          courseData: courseDetails,
          purchaseDetail: {
            package_id: courseDetails.id,
            price: allPrice,
            is_doubt: check,
          },
        },
      });
    } else {
      if (paymentOption === "") {
        setPaymentModal(true);
      } else {
        setIsprocessing(false);

        // **********************************RazorPay**************************************
        if (paymentOption === "razorpay") {
          const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
          if (!res) {
            alert("You are offline ...Failed to load Razorpay SDK", {
              type: "warning",
            });
            return;
          } else {
            const options = {
              key: "rzp_test_gZte4p2li4zBnk",
              currency: "INR",
              amount: allPrice * 100,
              name: "Adhigyanam",
              description: "Thanks for purchasing",
              image: Logo,
              prifill: {
                name: user?.name, //your customer's name
                email: user?.email,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              handler: function (response) {
                if (response) {
                  bookingHandler(response.razorpay_payment_id, paymentOption);
                } else {
                  swal("Failed!", "Payment Failed!", "error");
                }
              },
              // callback_url: "https://dev.adhigyanam.com/admin/razorpay-response",
              // redirect: true,
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        } else {
          bookingHandler("", paymentOption);
        }
      }
    }
  };

  const onCloseModal = () => {
    setPaymentModal(false);
  };

  const OnSelectVideoHandler = (vido, tittle, description) => {
    setVideoUrl(vido);
    setTitle(tittle);
    setDescription(description);
  };
  const onClickPaymentOption = (payOption) => {
    setPaymentModal(false);
    onPurchasePakageHandler(payOption);
  };
  // const onShowPaymentModal = () => {
  //   setPaymentModal(true);
  // };

  const onRedirectCategory = () => {
    const path = generatePath(`/course/${location.state.categoryId}`);
    navigate(path, { state: { categoryId: location.state.categoryId } });
  };
  const onRedirectCourse = () => {
    navigate(`/course`);
  };

  return (
    <>
      {paymentModal && (
        <ModalLayout onCloseHandler={onCloseModal}>
          <Modal onClose={onCloseModal} height="auto" overflowY="auto" width="50%">
            <div className={style.paymentOption}>
              <h1>Choose Payment Option</h1>
              <span className={style.paymentOption__cros} onClick={onCloseModal}>
                <img src={closeImg} alt="close" />
              </span>
              <div className={style.paymentOption__option}>
                <PaymentOption
                  name="pay"
                  img={phonPayimg}
                  id="phonepay"
                  value="phonepay"
                  onClick={() => onClickPaymentOption("phonepay")}
                />
                {/* <PaymentOption
                  name="pay"
                  img={razorPayimg}
                  id="razorpay"
                  value="razorpay"
                  onClick={() => onClickPaymentOption("razorpay")}
                /> */}
              </div>
            </div>
          </Modal>
        </ModalLayout>
      )}

      <div className={style.courseDetails__bannerArea}>
        {loading ? (
          <Loading />
        ) : (
          <div className={style.courseDetails__bannerArea__container}>
            <p>
              <span onClick={onRedirectCourse}>
                Adhigyanam Courses <i className="fa-solid fa-angle-right"></i>{" "}
              </span>
              <span onClick={onRedirectCategory}>
                Category <i className="fa-solid fa-angle-right"></i>
              </span>
              <span>{courseDetails.title}</span>
            </p>
            <h1>{courseDetails.title}</h1>
            <h4>{courseDetails.sub_title}</h4>
            <h5>
              Tought By : <span>{courseDetails.taught_by}</span>
            </h5>
            <h5>
              Language : <span>{courseDetails.language}</span>
            </h5>
            <div className={style.courseDetails__bannerArea__container__rattingArea}>
              <span>
                <Rating
                  name="half-rating-read"
                  defaultValue={courseDetails?.ratings ? courseDetails?.ratings : "0"}
                  precision={0.5}
                  readOnly
                />
              </span>
              <span>({courseDetails?.rating_count})</span>
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className={style.courseDetails}>
            <div className={style.courseDetails__detailsArea}>
              <div className={style.courseDetails__detailsArea__title}>
                <h1>Your Videos</h1>
                <p className={style.courseDetails__detailsArea__title__arrowTitle}></p>
              </div>
              <div className={style.courseDetails__detailsArea__videoArea}>
                <div className={style.courseDetails__detailsArea__videoArea__videoCard}>
                  <div className={style.courseDetails__detailsArea__videoArea__videoCard__video}>
                    <ReactPlayer
                      playing={true}
                      controls={true}
                      config={{
                        file: { attributes: { controlsList: "nodownload" } },
                      }}
                      width="100%"
                      height="100%"
                      url={videoUrl ? videoUrl : courseDetails?.demo_video}
                    />
                  </div>
                  <div className={style.courseDetails__detailsArea__videoArea__videoCard__detailArea}>
                    <h2>{title ? title : courseDetails.title}</h2>
                    <p>{vdescription ? vdescription : courseDetails.sub_title}</p>
                    <span>
                      <Rating
                        name="half-rating-read"
                        defaultValue={courseDetails.ratings ? courseDetails.ratings : "0"}
                        precision={0.5}
                        readOnly
                      />
                    </span>
                  </div>
                </div>
                <div
                  className={style.courseDetails__detailsArea__videoArea__priceArea}
                  style={{
                    overflowY: `${courseDetails.is_subscribed === "1" && token ? "auto" : "hidden"}`,
                  }}>
                  <>
                    {vLoding ? (
                      <div className={style.vLoding}>
                        <Loading />
                      </div>
                    ) : (
                      <>
                        {videos.length <= 0 ? (
                          <span className={style.emptyMsg}>
                            <img src={noDataFoundImg} alt="err" />
                          </span>
                        ) : (
                          <>
                            {videos.map((item) => {
                              return (
                                <VideoCard
                                  key={item.id}
                                  videoDate={item.video_date}
                                  tittle={item.title}
                                  description={item.description}
                                  onClick={() => OnSelectVideoHandler(item.video_url, item.title, item.description)}
                                  ratting={courseDetails.ratings ? courseDetails.ratings : "0"}
                                />
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </>

                  <div
                    className={style.courseDetails__detailsArea__videoArea__priceArea__overLay}
                    style={{
                      display: `${courseDetails.is_subscribed === "1" && token ? "none" : "flex"}`,
                    }}>
                    <div className={style.courseDetails__detailsArea__videoArea__priceArea__overLay__detail}>
                      <img src={logoImg} alt="err" />
                      <h1>Buy Now To Access All Sessions</h1>
                      {/* {courseDetails.doubt === "1" && (
                        <div className={style.courseDetails__detailsArea__videoArea__priceArea__overLay__detail__checkbox}>
                          <input type="checkbox" name="check" id="check" onChange={onCheckDoubtPakage} />
                          <label htmlFor="check">Add Live Doubt Clearing Session</label>
                        </div>
                      )} */}

                      {/* {paymentOption && (
                        <div className={style.showPayment}>
                          <p>
                            Payment Option : <span>{paymentOption}</span>{" "}
                          </p>
                          <span>
                            <i onClick={onShowPaymentModal} class="fa-solid fa-pen-to-square"></i>
                          </span>
                        </div>
                      )} */}
                      <Button
                        btnName={isProcessing ? `Pay ₹ ${allPrice}` : "Processing.."}
                        width="50%"
                        marginTop="15px"
                        fontSize="18px"
                        onClick={() => onPurchasePakageHandler("")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.courseDetails__detailsArea__descriptionArea}>
                <div className={style.courseDetails__detailsArea__descriptionArea__cardArea}>
                  <h1>
                    <i className="fa-solid fa-book-tanakh"></i> Description{" "}
                  </h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: courseDetails.description,
                    }}
                  />
                </div>
                <div className={style.courseDetails__detailsArea__descriptionArea__cardArea}>
                  <h1>
                    <i className="fa-solid fa-book-tanakh"></i> What You Will Learn{" "}
                  </h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: courseDetails.what_you_will_learn,
                    }}
                  />
                </div>
                <div className={style.courseDetails__detailsArea__descriptionArea__cardArea}>
                  <h1>
                    <i className="fa-solid fa-book-tanakh"></i> What You Will Get{" "}
                  </h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: courseDetails.what_you_will_get,
                    }}
                  />
                </div>
                <div className={style.courseDetails__detailsArea__descriptionArea__cardArea}>
                  <h1>
                    <i className="fa-solid fa-book-tanakh"></i> Course Content{" "}
                  </h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: courseDetails?.course_content,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PurchaseVideo;
