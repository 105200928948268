import React from "react";
import style from "./index.module.scss";
import CustomeBanner from "../../components/CustomeBanner";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../../components/Loading";
import CourseBg from "../../assets/learnBg.webp";
import CoursePakage from "./CoursePackage";
import cardImg from "../../assets/c7.webp";
import noDataFoundImg from "../../assets/noData.png";

const CourseVideo = () => {
  const param = useParams();
  let user = JSON.parse(localStorage.getItem("user"));
  let token = user.token;
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // *********************Video Details**************************
    setLoading(true);
    const formdata = new FormData();
    formdata.append("category", location?.state?.categoryId);
    const getPakage = {
      url: "https://dev.adhigyanam.com/admin/api/course-package-details",
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formdata,
    };
    // ***********************************************

    axios(getPakage)
      .then((res) => {
        if (res.data.status) {
          setPackages(res.data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    // eslint-disable-next-line
  }, [param.id, token]);

  const onBuyCourseHandler = (id, item) => {
    const path = generatePath(`/course/${btoa(id)}/purchase-video`);
    navigate(path, { state: { categoryId: location.state.categoryId } });
  };

  return (
    <>
      <div className={style.courseDetails}>
        <CustomeBanner
          width="100%"
          bgImage={CourseBg}
          title="Learn Bhartiya Science"
          desc="Welcome to Adhigyanam, your gateway to the revival of ancient Bhartiya Sciences. Inspired by the revered Gurukul system, we offer an Online E-Learning Platform where enthusiasts can explore Prediction Sciences & Vedic Scriptures. Our courses cover Astrology, Numerology, Palmistry, Sanskrit Language, and more, presented by experienced Jyotishacharyas and Teachers in easy-to-understand videos. We aim to bridge the gap between the past and present, making the profound knowledge of Ancient Bhartiya Sciences accessible to all. Join us in our mission to revive Bhartiya Sciences, contributing to a global renaissance. Embrace the wisdom of the ages at Adhigyanam."
        />
        {loading && (
          <div style={{ zIndex: 200 }} className={style.container__loader}>
            <Loading />
          </div>
        )}
        {!loading && (
          <>
            {packages.length <= 0 ? (
              <span className={style.emptyMsg}>
                <img src={noDataFoundImg} alt="err" />
              </span>
            ) : (
              <>
                <div className={style.courseDetails__detailsArea}>
                  {packages.map((item) => {
                    return (
                      <CoursePakage
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        sub_title={item.sub_title}
                        src={item.thumbnail ? item.thumbnail : cardImg}
                        language={item.language}
                        price={item.is_subscribed === "1" ? "Paid" : item.price}
                        rating={item.ratings}
                        btnName={item.is_subscribed === "1" ? "View" : "View Course"}
                        onBuyCourseHandler={() => onBuyCourseHandler(item.id, item)}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CourseVideo;
