import React, { useCallback, useContext, useEffect, useState } from "react";
import style from "./index.module.scss";
import ModalLayout from "../../components/ModalLayout";
import InputField from "../../widgets/InputField";
import Button from "../../widgets/Button";
import axios from "axios";
import { endpoints } from "../../utils/Endpoints";
import swal from "sweetalert";
import PhoneNumber from "../../components/PhoneInput";
import { Context } from "../../utils/ContextStor";
import { useNavigate } from "react-router-dom/dist";
import Login from "../Login";
import SignUp from "../SignUp";
import Modal from "..";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { loadScript } from "../../helpers/razorpay";
import PaymentOption from "../../components/PaymentOption";
import phonPayimg from "../../assets/PhonePe_Logo.svg.png";
// eslint-disable-next-line
import razorPayimg from "../../assets/Razorpay_payments.png";
import closeImg from "../../assets/close.svg";
import Logo from "../../assets/LOGO.webp";

const PanditJiModal = ({ poojaDet, onCloseHandler, setShowPanditJiModal, price }) => {
  const [signup, setSignup] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState();
  const [data, setData] = useState({
    name: "",
    time: "",
    location: "",
    email: "",
  });
  const [phone, setPhone] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [token, setToken] = useContext(Context);
  const userData = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  /********************************************Booking Form Data ************************************************/
  const bookingDet = (e, id) => {
    let key = id;
    let value = e.target.value;
    let newData = { ...data };
    newData[key] = value;
    setData(newData);
  };

  // *****************************Start Booking Here*************************
  const bookingHandler = (paymentId, paymentOption) => {
    const formdata = new FormData();
    formdata.append("poojaservice_id", poojaDet.id);
    formdata.append("name", data.name);
    formdata.append("location", data.location);
    formdata.append("date", selectedDate);
    formdata.append("time",data.time);
    formdata.append("email",data.email);
    formdata.append("phone_no", phone);
    formdata.append("price", price);
    formdata.append("payment_id", paymentId);
    formdata.append("pg_type", paymentOption);

    setLoading(true);

    const getConfig = {
      url: endpoints.bookPandit,
      method: "POST",
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
      data: formdata,
    };
    axios(getConfig)
      .then((res) => {
        if (res.data.status === true) {
          window.location.replace(res.data.url);

          setLoading(false);
          setData({
            name: "",
            location: "",
          });
          setPhone("");
          setShowPanditJiModal(false);
        } else {
          swal("Failed!", "Somthing went wrong!", "error");
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const onBookedPanditJi = async (paymentOption) => {
    if (data.name === "") {
      return swal("Failed!", "Name Is Required!", "error");
    }
     else if (data.time === "") {
      return swal("Failed!", "Time Is Required!", "error");
    }
     else if (data.location === "") {
      return swal("Failed!", "Location Is Required!", "error");
    }
    else if (phone === "" || phone === undefined) {
      return swal("Failed!", "Phone Is Required!", "error");
    } 
     else if (data.email === "" || !data.email.includes("@") || !data.email.includes(".")) {
      return swal("Failed!", "Email Is Required!", "error");
    }
     else if (paymentOption === "") {
      setPaymentModal(true);
      return;
    }
    if (userData) {
      // **********************************RazorPay**************************************
      if (paymentOption === "razorpay") {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if (!res) {
          alert("You are offline ...Failed to load Razorpay SDK", {
            type: "warning",
          });
          return;
        } else {
          const options = {
            key: "rzp_test_gZte4p2li4zBnk",
            currency: "INR",
            amount: price * 100,
            name: "Adhigyanam",
            description: "Thanks for purchasing",
            image: Logo,
            prifill: {
              name: userData?.name, //your customer's name
              email: userData?.email,
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
            handler: function (response) {
              if (response) {
                bookingHandler(response.razorpay_payment_id, paymentOption);
              } else {
                swal("Failed!", "Payment Failed!", "error");
              }
            },
            // callback_url: "https://dev.adhigyanam.com/admin/razorpay-response",
            // redirect: true,
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
      } else {
        bookingHandler("", paymentOption);
      }
    } else {
      swal("Failed!", "Login first and Proceed to Pay", "error");
      setLoginModal(true);
    }
  };
  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };

  const onSelectDateHandler = (date) => {
    let userDob = new Date(date);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    setStartDate(date);
  };
  useEffect(() => {
    let userDob = new Date(startDate);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    // eslint-disable-next-line
  }, []);
  // **************************Loging************************************
  const onLogin = useCallback(async (userDetails) => {
    setToken(userDetails.token);
    setLoginModal(false);
    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speciality: userDetails.speciality,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.Gender,
      contact_no: userDetails.contact_no,
      language: userDetails.Language,
    };
    localStorage.setItem("token", userDetails.token);
    localStorage.setItem("user", JSON.stringify(filteredData));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);

    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);

  const onSignupHandler = () => {
    setLoginModal(false);
    setSignup(true);
  };
  const onCancelSignUp = () => {
    setLoginModal(true);
    setSignup(false);
  };
  const onCloseModal = () => {
    setLoginModal(false);
    setSignup(false);
    setPaymentModal(false);
  };
  const onClickPaymentOption = (payOption) => {
    setPaymentModal(false);
    onBookedPanditJi(payOption);
  };
  // const onShowPaymentModal = () => {
  //   setPaymentModal(true);
  // };
  return (
    <>
      {paymentModal && (
        <ModalLayout onCloseHandler={onCloseModal}>
          <Modal onClose={onCloseModal} height="auto" overflowY="auto" width="50%">
            <div className={style.paymentOption}>
              <h1>Choose Payment Option</h1>
              <span className={style.paymentOption__cros} onClick={onCloseModal}>
                <img src={closeImg} alt="close" />
              </span>
              <div className={style.paymentOption__option}>
                <PaymentOption
                  name="pay"
                  img={phonPayimg}
                  id="phonepay"
                  value="phonepay"
                  onClick={() => onClickPaymentOption("phonepay")}
                />
                {/* <PaymentOption
                  name="pay"
                  img={razorPayimg}
                  id="razorpay"
                  value="razorpay"
                  onClick={() => onClickPaymentOption("razorpay")}
                /> */}
              </div>
            </div>
          </Modal>
        </ModalLayout>
      )}

      {loginModal && (
        <Modal border="1px solid #1eb2a6" onClose={onCloseModal} overflowY="auto">
          <Login onSignupHandler={onSignupHandler} onCloseHandler={onCloseModal} onLoginHandler={onLogin} />
        </Modal>
      )}
      {signup && (
        <Modal border="1px solid #1eb2a6" onClose={onCloseModal}>
          <SignUp setSignup={setSignup} onCloseHandler={onCloseModal} onCancelSignUp={onCancelSignUp} />
        </Modal>
      )}
      <ModalLayout onCloseHandler={onCloseHandler} padding="10px">
        <div className={style.panditJiModal}>
          <div className={style.panditJiModal__left}>
            <div className={style.panditJiModal__left__pooja}>
              <img src={poojaDet.icon} alt="pooja" />
            </div>
            <h1>{poojaDet.name}</h1>
            <p>{poojaDet.description}</p>
          </div>
          <div className={style.panditJiModal__right}>
            <div className={style.panditJiModal__right__slot}>
              <p>
                Time Slot : {poojaDet.start_time} to {poojaDet.end_time}
              </p>
            </div>
            <InputField
              border="1px solid #34baaf"
              value={data.name}
              onChange={bookingDet}
              marginTop="10px"
              marginBottom="5px"
              label="Name"
              id="name"
              placeholder="Name"
              borderRadius="4px"
            />
           
            <div className={style.panditJiModal__right__select}>
              <p>Date</p>
              <DatePicker
                minDate={new Date()}
                className={style.datePicker}
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={onSelectDateHandler}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
             <InputField
              border="1px solid #34baaf"
              value={data.time}
              onChange={bookingDet}
              marginTop="10px"
              marginBottom="20px"
              label="Time"
              id="time"
              placeholder="Time"
              borderRadius="4px"
              type="time"
            />
             <InputField
              border="1px solid #34baaf"
              value={data.location}
              onChange={bookingDet}
              marginTop="10px"
              marginBottom="0px"
              label="Location"
              id="location"
              placeholder="Location"
              borderRadius="4px"
            />
            <div className={style.panditJiModal__right__select}>
              <p>Enter Phone</p>
              <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneHandler} />
            </div>
              <InputField
              border="1px solid #34baaf"
              value={data.email}
              onChange={bookingDet}
              marginTop="10px"
              marginBottom="20px"
              label="Email"
              id="email"
              placeholder="Email"
              borderRadius="4px"
              type="email"
            />
            {/* {paymentOption && (
              <div className={style.showPayment}>
                <p>
                  Payment Option : <span>{paymentOption}</span>{" "}
                </p>
                <span>
                  <i onClick={onShowPaymentModal} class="fa-solid fa-pen-to-square"></i>
                </span>
              </div>
            )} */}
            <div>
              <Button
                btnName={loading ? "Booking..." : "Book Now"}
                fontSize="16px"
                textTransform="capitalize"
                onClick={() => onBookedPanditJi("")}
              />
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default PanditJiModal;
