import React, { useContext, useEffect, useRef, useState } from "react";
import Styles from "./index.module.scss";
import Modal from "../../modal";
import DragAndDrop from "../DragAndDrop";
import pdfImg from "../../assets/pdfimg.svg";
import rightTick from "../../assets/rightick.png";
import axios from "axios";
import swal from "sweetalert";
import Button from "../../widgets/Button";
import ModalLayout from "../ModalLayout";
import Chat from "../../pages/Chat";
import ChatButton from "../ChatButton";
import { initializeApp } from "firebase/app";
import { collection, getDocs, query, getFirestore } from "firebase/firestore";
import { Context } from "../../utils/ContextStor";
// eslint-disable-next-line
import {
  FaHandPointLeft,
  FaHandPointRight,
  // FaMinus,
  // FaPlus,
  // FaReadme,
} from "react-icons/fa";
import eyeImg from "../../assets/newassets/openEye.png"
const servicesLists = [
  { id: 1, name: "Pending" },
  { id: 2, name: "Inprocess" },
  { id: 3, name: "Completed" },
];
const bookingStatusList = [
  { id: 1, name: "Pending" },
  { id: 2, name: "Completed" },
];
const fileTab = [
  { id: 0, name: "First File" },
  { id: 1, name: "Second File" },
  { id: 2, name: "Third File" },
  { id: 3, name: "Forth File" },
  { id: 4, name: "Fifth File" },
  { id: 5, name: "Sixth File" },
  { id: 6, name: "Seventh File" },
];
const BookingCard = ({
  imgSrc,
  name,
  astroType,
  detail,
  time,
  date,
  id,
  status,
  hasFile,
  booking_status,
  items,
  topics,
  sub_service_id
}) => {
  // eslint-disable-next-line
  const [token1, setToken, updatedUser, setUpdatedUser] = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [documentFile, setDocumentFile] = useState();
  const [showDoc, setShowDoc] = useState();
  const [documentPath, setDocumentPath] = useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [isLess, setIsLess] = useState(false);
  const [fileName, setFileName] = useState(0);
  const [userData, setUserData] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [tabLength, setTabLength] = useState(4);
  const [allTopics, setAllTopics] = useState(topics?.slice(0, 2));
  // const [tabIndex, setTabIndex] = useState(0);
  const [statusList, setStatusList] = useState({
    docStatus: status,
  });
  const [otherStatuslist, setOtherStatusList] = useState({
    booking_status: booking_status,
  });
  const [planeDetail, setPlaneDetail] = useState(`${detail?.slice(0, 20)}..`);
  const [showPlaneDetail, setShowPlaneDetail] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));
  let token = user.token;
  // eslint-disable-next-line
  const [show, setShow] = useState(false);
  const divRef = useRef();
  const preRef = useRef(null);

  let isTrue = true;

  const firebaseConfig = {
    apiKey: "AIzaSyCAW8yOvKXsm_xkujJ68l5JklxBayuepBU",
    authDomain: "adhigyanam-8ab37.firebaseapp.com",
    projectId: "adhigyanam-8ab37",
    storageBucket: "adhigyanam-8ab37.appspot.com",
    messagingSenderId: "61129824412",
    appId: "1:61129824412:web:e0b4eb571950370408b559",
  };

  // const firebaseConfig = {
  //   apiKey: "AIzaSyB6-Tqb3p5tt4ERZFjL4V8TUk0d9oR-Bbg",
  //   authDomain: "adhigyanam-chatt.firebaseapp.com",
  //   projectId: "adhigyanam-chatt",
  //   storageBucket: "adhigyanam-chatt.appspot.com",
  //   messagingSenderId: "348490367087",
  //   appId: "1:348490367087:web:ba11c4110ee04d76b6cd9a",
  // };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const [allMessages, setAllMessages] = useState([]);
  const [chat, setChat] = useState(false);

  const getMessages = async () => {
    try {
      // Reference to the "messages" collection within the specified chat room
      const messagesRef = collection(
        db,
        "chat-consultation",
        `${items.customer_id}${items.astrologer_id}`,
        "messages"
      );
      const messageQuery = query(messagesRef);

      // Retrieve documents from the collection
      const querySnapshot = await getDocs(messageQuery);

      const fetchedMessages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAllMessages(fetchedMessages);
    } catch (error) {
      console.error("Error getting messages: ", error);
    }
  };

  getMessages();

  const openChat = () => {
    setChat(true);
    preRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onCloseChat = () => {
    setChat(false);
  };
  const onSelectStatus = (e) => {
    if (e.target.value === "3" && status !== "3" && documentPath === "") {
      swal("Please Upload PDF First", "Please Upload PDF First", "error");
      setShowModal(true);
    } else {
      // if (e.target.value === "2") {
      //   setShowDetailsModal(true);
      // }
      setStatusList({
        docStatus: e.target.value,
      });
      const formData = new FormData();
      formData.append("booking_id", id);
      formData.append("status", e.target.value);
      const postData = {
        url: "https://dev.adhigyanam.com/admin/api/changeKundaliStatus",
        method: "POST",
        data: formData,
      };
      axios(postData)
        .then((res) => {
          // setUserData(res.data.data);
          // console.log(res.data, "hhhhhhhhhhhhh");
          if (!res.data.data) {
            swal(res.data.message, res.data.message, "error");
               setStatusList({
                  docStatus: status
                });
          } else {
            swal(res.data.message, res.data.message, "success");
            setUserData(res.data.data);
          
            if(res.data.status === false) {
                setStatusList({
                    docStatus: status
                });
              }
          }
        })
        .catch((err) => {
          console.log(err);
        });

    }
    if (e.target.value === "3" && documentPath !== "") {
      const formData = new FormData();
      formData.append("kundali_pdf", documentPath);
      formData.append("booking_id", id);
      const postData = {
        url: "https://dev.adhigyanam.com/admin/api/kundaliUploadPdf",
        method: "POST",
        data: formData,
      };
      axios(postData)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const onCloseHandler = () => {
    setShowModal(false);
    setShowDetailsModal(false);
    setShowFileModal(false);
  };
  const onAddDocument = (e) => {
    let path = e.target.files[0];
    setDocumentPath(path);
    let image = URL.createObjectURL(path);

    if (path?.name?.includes(".pdf")) {
      setDocumentFile(pdfImg);
      setShowDoc(true);
    } else {
      setShowDoc(true);
      setDocumentFile(image);
    }
    setShowModal(false);
  };
  const onUplodPdf = () => {
    setShowModal(true);
  };

  const onShowFileHandler = (id) => {
    const formData = new FormData();
    formData.append("booking_id", id);
    let fileData = {
      url: "https://dev.adhigyanam.com/admin/api/get-releated-documents",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    axios(fileData)
      .then((res) => {
        setFileData(res.data.data);
        setTabLength(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
      });

    // *****************************

    setShowFileModal(true);
  };
  const onOpenFileHandler = (id) => {
    setFileName(id);
  };

  const onSubmitBookingStatus = (e) => {
    setOtherStatusList({
      booking_status: e.target.value,
    });
    const formData = new FormData();
    formData.append("booking_id", id);
    formData.append("status", e.target.value);
    const postData = {
      url: "https://dev.adhigyanam.com/admin/api/changeKundaliStatus",
      method: "POST",
      data: formData,
    };
    axios(postData)
      .then((res) => {
        if (!res.data.data) {
          swal(res.data.message, res.data.message, "error");
           setOtherStatusList({
                booking_status: status
             });
        } else {
          setUserData(res.data.data);
          swal(res.data.message, res.data.message, "success");
          if(res.data.status === false){
               setOtherStatusList({
                booking_status: status
             });
          }
          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (isTrue) {
        if (divRef.current && !divRef.current.contains(event.target)) {
          setShow(false);
          setChat(false);
        }
      } else {
        // eslint-disable-next-line
        isTrue = true;
        return;
      }
    };
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const onReadMore = () => {
    setAllTopics(topics);
    setIsLess(true);
  };

  const onReadLess = () => {
    setAllTopics(topics.slice(0, 2));
    setIsLess(false);
  };

  const onShowPlan = () => {
    setPlaneDetail(detail);
    setShowPlaneDetail(true);
  };
  const onHidePlan = () => {
    setPlaneDetail(`${detail.slice(0, 20)}..`);
    setShowPlaneDetail(false);
  };


  const onShowUserData = () => {
    const formData = new FormData();
      formData.append("booking_id", id);
      formData.append("get_user_data",1)
      const postData = {
        url: "https://dev.adhigyanam.com/admin/api/changeKundaliStatus",
        method: "POST",
        data: formData,
      };
      axios(postData)
        .then((res) => {
          if (!res.data.data) {
            swal(res.data.message, res.data.message, "error");
          } else {
            setUserData(res.data.data);
            setShowDetailsModal(true);
            }
        })
        .catch((err) => {
          console.log(err);
        });
  }


  return (
    <>
      {showFileModal && (
        <Modal
          width="50%"
          height="auto"
          overflowY="hidden"
          onClose={onCloseHandler}
        >
          <ModalLayout onCloseHandler={onCloseHandler}>
            <div className={Styles.userFileArea}>
              <h2>User Files</h2>
              <ul>
                {fileTab.map((item, index) => {
                  return (
                    index < tabLength && (
                      <li
                        key={index}
                        className={item.id === fileName ? Styles.activeTab : ""}
                        onClick={() => onOpenFileHandler(item.id)}
                      >
                        {item.name}
                      </li>
                    )
                  );
                })}
              </ul>
              <div className={Styles.userFileArea__userImgArea}>
                <img src={fileData[fileName]?.sample} alt="err" />;
              </div>
            </div>
          </ModalLayout>
        </Modal>
      )}
      {showDetailsModal && (
        <Modal
          width="50%"
          height="auto"
          overflowY="auto"
          onClose={onCloseHandler}
        >
          <ModalLayout onCloseHandler={onCloseHandler}>
            <div className={Styles.userDetailArea}>
              <h2>User Details</h2>
              {(sub_service_id === 3 || sub_service_id === 2) ? <>
                <ul>
                  {/* *****************************Male Details************************* */}
                  <h2>Male Details</h2>
                   <li>
                  {" "}
                    <span className={Styles.userDetailArea__identitiText}>
                     Name :
                    </span>{" "}
                    <span>{userData?.male_name ? userData?.male_name : "--"}</span>
                  </li>
                  <li>
                  {" "}
                    <span className={Styles.userDetailArea__identitiText}>
                    Language :
                    </span>{" "}
                    <span>{userData?.male_language ? userData?.male_language : "--"}</span>
                  </li>
                    <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                     Date Of Birth :
                  </span>{" "}
                  <span>{userData?.male_date_of_birth ? new Date(userData?.male_date_of_birth).getDate() + "-" + (new Date(userData?.male_date_of_birth).getMonth()+1) + "-" + new Date(userData?.male_date_of_birth).getFullYear() : "--"}</span>
                  </li>
                  <li>
                    <span className={Styles.userDetailArea__identitiText}>
                     Time of Birth :
                    </span>{" "}
                    <span>{userData?.male_time_of_birth ? userData?.male_time_of_birth : "--"}</span>
                  </li>
                   <li>
                    <span className={Styles.userDetailArea__identitiText}>
                     Place of Birth :
                    </span>{" "}
                    <span>{userData?.male_place_of_birth ? userData?.male_place_of_birth : "--"}</span>
                  </li>
                       <h2>Female Details</h2>

                  {/* *****************************Female Details************************* */}
                  <li>
                  {" "}
                    <span className={Styles.userDetailArea__identitiText}>
                     Name :
                    </span>{" "}
                    <span>{userData?.female_name ? userData?.female_name : "--"}</span>
                  </li>
                  <li>
                  {" "}
                    <span className={Styles.userDetailArea__identitiText}>
                    Language :
                    </span>{" "}
                    <span>{userData?.female_language ? userData?.female_language : "--"}</span>
                  </li>
                    <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                     Date Of Birth :
                  </span>{" "}
                  <span>{userData?.female_date_of_birth ? new Date(userData?.female_date_of_birth).getDate() + "-" + (new Date(userData?.female_date_of_birth).getMonth()+1) + "-" + new Date(userData?.female_date_of_birth).getFullYear() : "--"}</span>
                  </li>
                  <li>
                    <span className={Styles.userDetailArea__identitiText}>
                     Time of Birth :
                    </span>{" "}
                    <span>{userData?.female_time_of_birth ? userData?.female_time_of_birth : "--"}</span>
                  </li>
                   <li>
                    <span className={Styles.userDetailArea__identitiText}>
                     Place of Birth :
                    </span>{" "}
                    <span>{userData?.female_place_of_birth ? userData?.female_place_of_birth : "--"}</span>
                  </li>
                </ul>
              
              </> :   <ul>
                <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                    Name :
                  </span>{" "}
                  <span>{userData?.name ? userData?.name : "--"}</span>
                </li>
                <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                    Gender :
                  </span>{" "}
                  <span>{userData?.gender ? userData?.gender : "--"}</span>
                </li>
                <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                    Language :
                  </span>{" "}
                  <span>{userData?.language ? userData?.language : "--"}</span>
                </li>
                <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                    Date Of Birth :
                  </span>{" "}
                  <span>{userData?.date_of_birth ? new Date(userData?.date_of_birth).getDate() + "-" + (new Date(userData?.date_of_birth).getMonth()+1) + "-" + new Date(userData?.date_of_birth).getFullYear() : "--"}</span>
                </li>
                <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                    Time Of Birth :
                  </span>{" "}
                  <span>{userData?.time_of_birth ? userData?.time_of_birth : "--"}</span>
                </li>
                <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                    Place Of Birth :
                  </span>{" "}
                  <span>{userData?.place_of_birth ? userData?.place_of_birth : "--"}</span>
                </li>
                <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                    Father Name :
                  </span>{" "}
                  <span>{userData?.father_name ? userData?.father_name : "--"}</span>
                </li>
                <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                    Mother Name :
                  </span>{" "}
                  <span>{userData?.mother_name ? userData?.mother_name : "--"}</span>
                </li>
                <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                    Grand Father Name :
                  </span>{" "}
                  <span>{userData?.paternal_female ? userData?.paternal_female : "--"}</span>
                </li>
                <li>
                  {" "}
                  <span className={Styles.userDetailArea__identitiText}>
                    Grand Mother Name :
                  </span>{" "}
                  <span>{userData?.paternal_male ? userData?.paternal_male : "--"}</span>
                </li>
              </ul>}

            </div>
          </ModalLayout>
        </Modal>
      )}
      {showModal ? (
        <Modal width="40%" height="40%" onClose={onCloseHandler}>
          <ModalLayout onCloseHandler={onCloseHandler}>
            <div className={Styles.dragArea}>
              <DragAndDrop
                image={documentFile}
                showFile={showDoc}
                filesHandler={onAddDocument}
                id="documentImage"
                fileType="UPLOAD DOCUMENT"
                type="(Kundli PDF)"
                pdf=".pdf"
                height="250px"
                pHeight="80%"
              />
            </div>
          </ModalLayout>
        </Modal>
      ) : (
        <div className={Styles.cardArea} innerRef={preRef}>
          {items.consultation_type_name === "chat consultation" && (
            <div ref={divRef}>
              {chat && (
                <Chat
                  innerRef={preRef}
                  onCloseChat={onCloseChat}
                  db={db}
                  allMessages={allMessages}
                  items={items}
                />
              )}
              <ChatButton openChat={openChat} />
            </div>
          )}
          <span className={Styles.cardArea__cardImg}>
            <img src={imgSrc} alt="error" />
          </span>
          <h3 className={Styles.cardArea__userNameImg}>{name} <img onClick={onShowUserData} src={eyeImg} alt="img" /> </h3>
          <p className={Styles.cardArea__serviceType}>
            Service type : <span>{astroType}</span>
          </p>
          <p className={Styles.cardArea__detail}>
            {planeDetail}
            {!showPlaneDetail && (
              <span
                onClick={onShowPlan}
                className={Styles.cardArea__topics__ul__read}
              >
                <FaHandPointRight />
              </span>
            )}
            {showPlaneDetail && (
              <span
                onClick={onHidePlan}
                className={Styles.cardArea__topics__ul__read}
              >
                <FaHandPointLeft />
              </span>
            )}
          </p>
          {topics?.length > 0 && (
            <div className={Styles.cardArea__topics}>
              <ul className={Styles.cardArea__topics__ul}>
                {allTopics?.map((item, index, arr) => {
                  return (
                    <li className={Styles.cardArea__topics__ul__li} key={index}>
                      {item}
                      {arr.length === index + 1 ? "" : ", "}
                    </li>
                  );
                })}
                {topics?.length > 2 ? ".." : ""}
                {topics?.length > 2 && (
                  <>
                    {!isLess && (
                      <li
                        onClick={onReadMore}
                        className={Styles.cardArea__topics__ul__read}
                      >
                        <FaHandPointRight />
                      </li>
                    )}
                    {isLess && (
                      <li
                        onClick={onReadLess}
                        className={Styles.cardArea__topics__ul__read}
                      >
                        <FaHandPointLeft />
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>
          )}
          {astroType === "Kundli" ? (
            <>
              <div className={Styles.selectArea}>
                <span onClick={onUplodPdf}>
                  {status === "3" || documentPath !== "" ? (
                    <img src={rightTick} alt="err" />
                  ) : (
                    "PDF"
                  )}
                </span>
                <select
                  name="status"
                  value={statusList.docStatus}
                  id="status"
                  onChange={onSelectStatus}
                >
                  {servicesLists.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              </div>
            </>
          ) : (
            <>
              <div className={Styles.cardArea__cardDateTime}>
                <p>{time}</p>
                <span>{date}</span>
              </div>

              {hasFile === 1 ? (
                <div className={Styles.statusArea}>
                  <Button
                    onClick={() => onShowFileHandler(id)}
                    btnName="View File"
                    marginTop="0"
                    marginBottom="0"
                  />
                  <div className={Styles.selectArea}>
                    <select
                      name="status"
                      value={otherStatuslist.booking_status}
                      id="status"
                      onChange={onSubmitBookingStatus}
                    >
                      {bookingStatusList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
              ) : (
                <div className={Styles.selectArea}>
                  <select
                    name="status"
                    value={otherStatuslist.booking_status}
                    id="status"
                    onChange={onSubmitBookingStatus}
                  >
                    {bookingStatusList.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default BookingCard;
