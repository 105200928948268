import React, { useState } from "react";
import CustomeBanner from "../../components/CustomeBanner";
import bgImage from "../../assets/learnBg.webp";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import style from "./index.module.scss";

const HoroscopeDetail = () => {
  const [horoDet, setHoroDet] = useState([]);
  const params = useParams();
  useEffect(() => {
    axios
      .get(`https://dev.adhigyanam.com/admin/api/horoscope-descgetid/${params.id}`)
      .then((res) => {
        setHoroDet(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);
  return (
    <div>
      <CustomeBanner textAlign="center" width="100%" title={horoDet?.name} bgImage={bgImage} />
      <div className={style.content}>
        <div className={style.content__icon}>
          <div>
            <img src={horoDet?.icon} alt={horoDet?.name} />
          </div>
        </div>
        <div className={style.content__desc}>
          <p dangerouslySetInnerHTML={{ __html: horoDet?.description }}></p>
        </div>
      </div>
    </div>
  );
};

export default HoroscopeDetail;
