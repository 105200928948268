import React, { useContext } from "react";
import style from "./index.module.scss";
import axios from "axios";
import swal from "sweetalert";
import { Context } from "../../../utils/ContextStor";

const AstroDetCard = ({ ratting, img, name, exprience, detail, numOfCun, id }) => {
  // eslint-disable-next-line
  const [token, setToken, updatedUser, setUpdatedUser] = useContext(Context);

  const onAvailableHandler = (val) => {
    const formData = new FormData();
    formData.append("astrologer_id", id);
    formData.append("is_active", val);
    const sendMsg = {
      url: "https://dev.adhigyanam.com/admin/api/status-active",
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    axios(sendMsg)
      .then((res) => {
        swal(res.data.message, "success");
      })
      .catch((err) => {
        console.log(err);
      });

    // setActive(val);
    setUpdatedUser({ ...updatedUser, is_active: val });
  };
  return (
    <div className={style.astroCardArea}>
      <div className={style.astroCardArea__ratting}>
        {/* <span>
          <img src={star} alt="error" />
        </span>
        <p>{ratting}</p> */}
      </div>
      <div className={style.astroCardArea__astroImage}>
        <img src={img} alt="error" />
      </div>
      <div className={style.astroCardArea__astroDetail}>
        <h3 className={style.astroCardArea__astroDetail__name}>{name}</h3>
        <span className={style.astroCardArea__astroDetail__exprience}>{exprience}</span>
        <p className={style.astroCardArea__astroDetail__details}>{detail}</p>
      </div>
      <div className={style.astroCardArea__available}>
        <p>Available</p>
        <div className={style.astroCardArea__available__btn}>
          <button
            onClick={() => onAvailableHandler(1)}
            className={`${parseInt(updatedUser?.is_active) === 1 ? style.astroCardArea__available__btn__active : ""}`}>
            YES
          </button>
          <button
            onClick={() => onAvailableHandler(2)}
            className={`${parseInt(updatedUser?.is_active) === 2 ? style.astroCardArea__available__btn__active : ""}`}>
            NO
          </button>
        </div>
      </div>
      <div className={style.astroCardArea__consultation}>
        <h1>{numOfCun}</h1>
        <h4>No. of consultations</h4>
      </div>
    </div>
  );
};

export default AstroDetCard;
