import React, { useState } from "react";
import style from "./index.module.scss";
import InputField from "../../../widgets/InputField";
import Button from "../../../widgets/Button";
import axios from "axios";
import PhoneNumber from "../../../components/PhoneInput";
import swal from "sweetalert";
const Form = () => {
  const [submit, setSubmit] = useState(true);
  const [detail, setDetail] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [phone, setPhone] = useState("");

  // ****************************************************
  const onChangeHandler = (e, id) => {
    setDetail({
      ...detail,
      [id]: e.target.value,
    });
  };

  const onSubmitHandler = () => {
    // eslint-disable-next-line
    const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    setSubmit(false);
    const formData = new FormData();
    formData.append("name", detail.name);
    formData.append("email", detail.email);
    formData.append("mobile_no", phone);
    formData.append("message", detail.message);

    if (detail.name === "") {
      setSubmit(true);
      swal("Name is required!", "Name is required!", "error");
    } else if (specialChars.test(detail.name)) {
      setSubmit(true);
      swal("Name should not contain special Characters or Number!", "Name should not contain special Characters or Number!", "error");
    } else if (detail.email === "" || !detail.email.includes("@") || !detail.email.includes(".com")) {
      setSubmit(true);
      swal("Enter valid Email!", "Enter valid Email!", "error");
    } else if (phone === "") {
      setSubmit(true);
      swal("Phone is required!", "Phone is required!", "error");
    } else if (detail.message === "") {
      setSubmit(true);
      swal("Message is required!", "Message is required!", "error");
    } else {
      const postData = {
        url: "https://dev.adhigyanam.com/admin/api/enquiry-store",
        method: "POST",
        data: formData,
      };
      axios(postData)
        .then((res) => {
          if (res.data.status === true) {
            setSubmit(true);
            swal("Message Sent Successfully!", "Good Job!", "success");

            setDetail({
              name: "",
              email: "",
              message: "",
            });
            setPhone("");
          } else {
            swal("Something went wrong!", "Something went wrong!", "error");

            setSubmit(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };
  return (
    <div className={style.form}>
      <div className={style.form__left}>
        <h2>Contact Us</h2>
        <InputField
          value={detail.name}
          id="name"
          border="1px solid #000"
          type="text"
          placeholder="Your Name"
          borderRadius="3px"
          onChange={onChangeHandler}
        />
        <InputField
          value={detail.email}
          id="email"
          border="1px solid #000"
          type="email"
          placeholder="Your Email"
          borderRadius="3px"
          onChange={onChangeHandler}
        />
        <PhoneNumber border="1px solid black" value={phone} onChange={onPhoneHandler} />

        <textarea
          value={detail.message}
          onChange={(e) => onChangeHandler(e, e.target.id)}
          className={style.form__left__textarea}
          placeholder="Your Message"
          name="message"
          style={{ border: "1px solid #000" }}
          id="message"
          cols="30"
          rows="5"></textarea>
        <div className={style.form__left__buttonWidth}>
          <Button fontSize="20px" btnName={submit ? "Submit" : "Processing.."} onClick={onSubmitHandler} />
        </div>
      </div>
    </div>
  );
};

export default Form;
