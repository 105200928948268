import React, { useCallback, useContext, useEffect } from "react";
import Styles from "./index.module.scss";
import PlanCommonHeading from "../../components/PlanCommonHeading";
import CopleImg from "../../assets/wedding.png";
import womanImg from "../../assets/woman.png";
import manImg from "../../assets/male.png";
import CommonInputField from "../../widgets/CommonInputField";
import Button from "../../widgets/Button";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneNumber from "../../components/PhoneInput";
import axios from "axios";
import CommonSelect from "../../components/CommonSelect";
import swal from "sweetalert";
import Modal from "../../modal";
import BookSlot from "../../modal/BookSlot";
import { endpoints } from "../../utils/Endpoints";
import Login from "../../modal/Login";
import SignUp from "../../modal/SignUp";
import { Context } from "../../utils/ContextStor";
import { useNavigate } from "react-router-dom/dist";
import { loadScript } from "../../helpers/razorpay";
import ModalLayout from "../../components/ModalLayout";
import PaymentOption from "../../components/PaymentOption";
import phonPayimg from "../../assets/PhonePe_Logo.svg.png";
// eslint-disable-next-line
import razorPayimg from "../../assets/Razorpay_payments.png";
import closeImg from "../../assets/close.svg";
import Logo from "../../assets/LOGO.webp";

const languageList = [
  { id: 1, name: "Select" },
  { id: 2, name: "Hindi" },
  { id: 3, name: "English" },
];

const MatchMaking = () => {
  // *************************** Form Data ******************************/
  const [signup, setSignup] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [planid, setPlanid] = useState("");
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [slotTime, setSlotTime] = useState("Your Slot Time");
  const [slotDate, setSlotDate] = useState("Your Slot Date");
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState();
  const [startDate2, setStartDate2] = useState(new Date());
  const [selectedDate2, setSelectedDate2] = useState();
  const [submit, setSubmit] = useState(true);
  const [maleData, setMaleData] = useState({
    name: "",
    Language: languageList[0].name,
    timeOfBirth: "",
    placeOfBirth: "",
    email: "",
  });
  const [planData, setPlanData] = useState("");
  // eslint-disable-next-line
  const [token, setToken] = useContext(Context);
  // **************************** RazorPay*********************************
  const [price, setPrice] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  //  ********************************************************************
  const navigate = useNavigate();
  let astroData = JSON.parse(localStorage.getItem("astroDet"));
  const user = JSON.parse(localStorage.getItem("user"));
  const formObj = [
    {
      inputName: "Name",
      inputType: "text",
      display: "none",
      name: "name",
      id: "name",
      value: maleData.name,
    },
    {
      inputName: "dob",
      inputType: "text",
      display: "none",
      name: "dob",
      id: "dob",
      value: maleData.name,
    },
    {
      inputName: "Time Of Birth",
      inputType: "time",
      name: "timeOfBirth",
      id: "timeOfBirth",
      value: maleData.timeOfBirth,
    },
    {
      inputName: "Place Of Birth",
      inputType: "text",
      display: "none",
      name: "placeOfBirth",
      id: "placeOfBirth",
      value: maleData.placeOfBirth,
    },
    {
      inputName: "Email Id",
      inputType: "text",
      display: "none",
      name: "email",
      id: "email",
      value: maleData.email,
    },
  ];
  const onMaleChangeHandler = (e, id) => {
    setMaleData({
      ...maleData,
      [id]: e.target.value,
    });
  };
  const [femaleData, setFemaleData] = useState({
    name: "",
    Language: languageList[0].name,
    timeOfBirth: "",
    placeOfBirth: "",
    email: "",
  });
  const formObjFemale = [
    {
      inputName: "Name",
      inputType: "text",
      display: "none",
      name: "name",
      id: "name",
      value: femaleData.name,
    },
    {
      inputName: "dob",
      inputType: "text",
      display: "none",
      name: "dob",
      id: "dob",
      value: maleData.name,
    },
    {
      inputName: "Time Of Birth",
      inputType: "time",
      name: "timeOfBirth",
      id: "timeOfBirth",
      value: femaleData.timeOfBirth,
    },
    {
      inputName: "Place Of Birth",
      inputType: "text",
      display: "none",
      name: "placeOfBirth",
      id: "placeOfBirth",
      value: femaleData.placeOfBirth,
    },
    {
      inputName: "Email Id",
      inputType: "text",
      display: "none",
      name: "email",
      id: "email",
      value: femaleData.email,
    },
  ];
  const onFemaleChangeHandler = (e, id) => {
    setFemaleData({
      ...femaleData,
      [id]: e.target.value,
    });
  };

  // **********************Date of Birth **********************
  const onSelectDateHandler = (date) => {
    let userDob = new Date(date);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    setStartDate(date);
  };
  const onSelectDateHandler2 = (date) => {
    let userDob2 = new Date(date);
    let year2 = userDob2.getFullYear();
    let month2 = userDob2.getMonth() + 1;
    let day2 = userDob2.getDate();
    let smonth2 = month2 < 10 ? "0" + month2 : month2;
    let sday2 = day2 < 10 ? "0" + day2 : day2;
    setSelectedDate2(`${year2}-${smonth2}-${sday2}`);
    setStartDate2(date);
  };
  useEffect(() => {
    let userDob = new Date(startDate);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    /////////////////////////////////////////////////
    let userDob2 = new Date(startDate2);
    let year2 = userDob2.getFullYear();
    let month2 = userDob2.getMonth() + 1;
    let day2 = userDob2.getDate();
    let smonth2 = month2 < 10 ? "0" + month2 : month2;
    let sday2 = day2 < 10 ? "0" + day2 : day2;
    setSelectedDate2(`${year2}-${smonth2}-${sday2}`);
    // eslint-disable-next-line
  }, []);

  // **************************** SubmitData ***********************
  const bookingHandler = (paymentId, paymentOption) => {
    const formData = new FormData();
    formData.append("male_name", maleData.name);
    formData.append("male_date_of_birth", selectedDate);
    formData.append("male_time_of_birth", maleData.timeOfBirth);
    formData.append("male_place_of_birth", maleData.placeOfBirth);
    formData.append("male_emailid", maleData.email);
    formData.append("male_contact_no", phone);
    formData.append("male_language", maleData.Language);
    formData.append("female_name", femaleData.name);
    formData.append("female_date_of_birth", selectedDate2);
    formData.append("female_time_of_birth", femaleData.timeOfBirth);
    formData.append("female_place_of_birth", femaleData.placeOfBirth);
    formData.append("female_emailid", femaleData.email);
    formData.append("female_contact_no", phone2);
    formData.append("female_language", femaleData.Language);
    formData.append("request_type", 1);
    formData.append("astrologer_id", astroData.astrologerID);
    formData.append("predection_id", astroData.servicId);
    formData.append("predection_type_id", astroData.serviceType);
    formData.append("slot_time", slotTime);
    formData.append("slot_date", slotDate);
    formData.append("plan_id", planid);
    formData.append("payment_id", paymentId);
    formData.append("pg_type", paymentOption);
    formData.append("consultation_type", 'telephonic_consultation');


    const postData = {
      url: endpoints.matchMaking,
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: formData,
    };
    axios(postData)
      .then((res) => {
        if (res.data.status === true) {
          setSubmit(true);
          window.location.replace(res.data.url);
        } else {
          swal("Sorry :(", res.data.message);
          setSubmit(true);
        }
      })
      .catch((err) => {
        swal("Failed!", "Booking Failed!", "error");
        setSubmit(true);
      });
  };

  const onSubmitHandler = async (paymentOption) => {
    // eslint-disable-next-line
    const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    setSubmit(false);

    // ********************** POST DATA ************************
    if (maleData.name === "") {
      setSubmit(true);
      swal("Failed!", "Name is required", "error");
    } else if (specialChars.test(maleData.name)) {
      setSubmit(true);
      swal("Failed!", "Name should not contain special characters", "error");
    } else if (selectedDate === new Date()) {
      setSubmit(true);
      swal("Failed!", "Date Of Birth is required", "error");
    } else if (maleData.timeOfBirth === "") {
      setSubmit(true);
      swal("Failed!", "Time Of Birth is required", "error");
    } else if (maleData.placeOfBirth === "") {
      setSubmit(true);
      swal("Failed!", "Place Of Birth is required", "error");
    } else if (maleData.email === "" || !maleData.email.includes("@")) {
      setSubmit(true);
      swal("Failed!", "Please Enter Valid Email", "error");
    } else if (phone === "" || phone === undefined) {
      setSubmit(true);
      swal("Failed!", "Please enter valid number", "error");
    } else if (maleData.Language === "Select") {
      setSubmit(true);
      swal("Failed!", "Language is required", "error");
    } else if (femaleData.name === "") {
      setSubmit(true);
      swal("Failed!", "Name is required", "error");
    } else if (specialChars.test(femaleData.name)) {
      setSubmit(true);
      swal("Failed!", "Name should not contain special characters", "error");
    } else if (selectedDate2 === new Date()) {
      setSubmit(true);
      swal("Failed!", "Date Of Birth is required", "error");
    } else if (femaleData.timeOfBirth === "") {
      setSubmit(true);
      swal("Failed!", "Time Of Birth is required", "error");
    } else if (femaleData.placeOfBirth === "") {
      setSubmit(true);
      swal("Failed!", "Place Of Birth is required", "error");
    } else if (femaleData.email === "" || !femaleData.email.includes("@")) {
      setSubmit(true);
      swal("Failed!", "Please Enter Valid Email", "error");
    } else if (phone2 === "" || phone2 === undefined) {
      setSubmit(true);
      swal("Failed!", "Please enter valid number", "error");
    } else if (femaleData.Language === "Select") {
      setSubmit(true);
      swal("Failed!", "Language is required", "error");
    } else if (slotDate === "Your Slot Date") {
      setSubmit(true);
      swal("Failed!", "Please Choose Slot Date", "error");
    } else if (slotTime === "Your Slot Time") {
      setSubmit(true);
      swal("Failed!", "Please Choose Slot Time", "error");
    } else if (paymentOption === "") {
      setPaymentModal(true);
    } else {
      if (user) {
        // **********************************RazorPay**************************************
        if (paymentOption === "razorpay") {
          const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
          if (!res) {
            alert("You are offline ...Failed to load Razorpay SDK", {
              type: "warning",
            });
            return;
          } else {
            const options = {
              key: "rzp_test_gZte4p2li4zBnk",
              currency: "INR",
              amount: price * 100,
              name: "Adhigyanam",
              description: "Thanks for purchasing",
              image: Logo,
              prifill: {
                name: user?.name, //your customer's name
                email: user?.email,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              handler: function (response) {
                if (response) {
                  bookingHandler(response.razorpay_payment_id, paymentOption);
                } else {
                  swal("Failed!", "Payment Failed!", "error");
                }
              },
              // callback_url: "https://dev.adhigyanam.com/admin/razorpay-response",
              // redirect: true,
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        } else {
          bookingHandler("", paymentOption);
        }
      } else {
        swal("Failed!", "Login First And Proceed to Pay!", "error");

        setSubmit(true);
        setLoginModal(true);
      }
    }
  };
  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };
  const onPhoneHandler2 = (ph) => {
    setPhone2(ph);
  };
  useEffect(() => {
    const setPlan = {
      url: "https://dev.adhigyanam.com/admin/api/planService-get",
      method: "POST",
      data: {
        predection_id: astroData?.servicId,
        predection_type_id: astroData?.serviceType,
      },
    };
    //****************Plans************* */
    axios(setPlan)
      .then((res) => {
        setPlanData(res.data.data[0].description);
        setPlanid(res.data.data[0].id);
        setPrice(res.data.data[0].price);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  const onCancelHandler = () => {
    setMaleData({
      name: "",
      Language: languageList[0].name,
      timeOfBirth: "",
      placeOfBirth: "",
      email: "",
    });
    setFemaleData({
      name: "",
      Language: languageList[0].name,
      timeOfBirth: "",
      placeOfBirth: "",
      email: "",
    });
    setPhone("");
    setPhone2("");
  };
  const chooseYourBookSlot = () => {
    setModalIsVisible(true);
  };
  const onSelectedSlot = (date, time) => {
    setSlotDate(date);
    setSlotTime(time);
    setModalIsVisible(false);
  };
  const onCloseMoadal = () => {
    setModalIsVisible(false);
    setPaymentModal(false);
  };
  // **************************Loging************************************
  const onLogin = useCallback(async (userDetails) => {
    setToken(userDetails.token);
    setLoginModal(false);
    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speciality: userDetails.speciality,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.Gender,
      contact_no: userDetails.contact_no,
      language: userDetails.Language,
    };
    localStorage.setItem("token", userDetails.token);
    localStorage.setItem("user", JSON.stringify(filteredData));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);

    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);

  const onSignupHandler = () => {
    setLoginModal(false);
    setSignup(true);
  };
  const onCancelSignUp = () => {
    setLoginModal(true);
    setSignup(false);
  };
  const onCloseModal = () => {
    setLoginModal(false);
    setSignup(false);
  };
  const onClickPaymentOption = (payOption) => {
    setPaymentModal(false);
    onSubmitHandler(payOption);
  };
  // const onShowPaymentModal = () => {
  //   setPaymentModal(true);
  // };
  return (
    <>
      {paymentModal && (
        <ModalLayout onCloseHandler={onCloseMoadal}>
          <Modal onClose={onCloseMoadal} height="auto" overflowY="auto" width="50%">
            <div className={Styles.paymentOption}>
              <h1>Choose Payment Option</h1>
              <span className={Styles.paymentOption__cros} onClick={onCloseMoadal}>
                <img src={closeImg} alt="close" />
              </span>
              <div className={Styles.paymentOption__option}>
                <PaymentOption
                  name="pay"
                  img={phonPayimg}
                  id="phonepay"
                  value="phonepay"
                  onClick={() => onClickPaymentOption("phonepay")}
                />
                {/* <PaymentOption
                  name="pay"
                  img={razorPayimg}
                  id="razorpay"
                  value="razorpay"
                  onClick={() => onClickPaymentOption("razorpay")}
                /> */}
              </div>
            </div>
          </Modal>
        </ModalLayout>
      )}
      {modalIsVisible && (
        <Modal onClose={onCloseMoadal}>
          <BookSlot display="none" width="100%" padding="0" onClick={onSelectedSlot} modalIsVisible={modalIsVisible} />
        </Modal>
      )}
      {loginModal && (
        <Modal onClose={onCloseModal}>
          <Login onSignupHandler={onSignupHandler} onCloseHandler={onCloseModal} onLoginHandler={onLogin} />
        </Modal>
      )}
      {signup && (
        <Modal onClose={onCloseModal}>
          <SignUp setSignup={setSignup} onCloseHandler={onCloseModal} onCancelSignUp={onCancelSignUp} />
        </Modal>
      )}
      <div className={Styles.modalArea}>
        <PlanCommonHeading img={CopleImg} leftText="Married Life Analysis" middlText={planData} />
        <div className={Styles.modalArea__telephonicArea}>
          <span>Telephonic Consultation</span>
        </div>

        <div className={Styles.modalArea__modal}>
          <div className={Styles.modalArea__modal__formArea}>
            <div className={Styles.modalArea__modal__formArea__detail}>
              <CommanTitleWithLogo icon={manImg} tittle="Male Details" width2="40px" height="40px" />

              <div className={Styles.modalArea__modal__formArea__detail__formField}>
                {formObj.map((item, index) => {
                  if (item.id === "dob") {
                    return (
                      <div className={Styles.modalArea__modal__formArea__detail__formField__datepeker}>
                        <p>Date of Birth</p>
                        <DatePicker
                          maxDate={new Date()}
                          className={Styles.datePicker}
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          onChange={onSelectDateHandler}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    );
                  } else {
                    return (
                      <CommonInputField
                        name={item.name}
                        key={index}
                        type={item.inputType}
                        placeholder={item.inputName}
                        display={item.display}
                        onChange={(e) => onMaleChangeHandler(e, item.id)}
                        borderColor="#1eb2a6"
                        value={item.value}
                      />
                    );
                  }
                })}
                <div className={Styles.modalArea__modal__formArea__detail__formField__select}>
                  <p>Enter Phone</p>
                  <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneHandler} />
                </div>
                <CommonSelect value={maleData.Language} label="Language" list={languageList} onChange={onMaleChangeHandler} />{" "}
                {/* <p className={Styles.modalArea__modal__formArea__detail__formField__select__timeSlot}>{slotTime}</p> */}
                {/* {paymentOption && (
                  <div className={Styles.hidePayOption}>
                    <div className={Styles.showPayment}>
                      <p>
                        Payment Option : <span>{paymentOption}</span>{" "}
                      </p>
                      <span>
                        <i onClick={onShowPaymentModal} class="fa-solid fa-pen-to-square"></i>
                      </span>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
            <div className={Styles.modalArea__modal__formArea__detail}>
              <CommanTitleWithLogo icon={womanImg} tittle="Female Details" width2="35px" height="40px" />

              <div className={Styles.modalArea__modal__formArea__detail__formField}>
                {formObjFemale.map((item, index) => {
                  if (item.id === "dob") {
                    return (
                      <div className={Styles.modalArea__modal__formArea__detail__formField__datepeker}>
                        <p>Date of Birth</p>
                        <DatePicker
                          maxDate={new Date()}
                          className={Styles.datePicker}
                          dateFormat="dd/MM/yyyy"
                          selected={startDate2}
                          onChange={onSelectDateHandler2}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    );
                  } else {
                    return (
                      <CommonInputField
                        onChange={(e) => onFemaleChangeHandler(e, item.id)}
                        name={item.name}
                        key={index}
                        type={item.inputType}
                        placeholder={item.inputName}
                        display={item.display}
                        borderColor="#1eb2a6"
                        value={item.value}
                      />
                    );
                  }
                })}
                <div className={Styles.modalArea__modal__formArea__detail__formField__select}>
                  <p>Enter Phone</p>
                  <PhoneNumber border="1px solid #03b2cb" value={phone2} onChange={onPhoneHandler2} />
                </div>

                {/* <p className={Styles.modalArea__modal__formArea__detail__formField__select__timeSlot}>{slotDate}</p> */}
                <CommonSelect value={femaleData.Language} label="Language" list={languageList} onChange={onFemaleChangeHandler} />

                <div>
                  <Button
                    // width="100%"
                    marginTop="25px"
                    marginBottom="0"
                    fontSize="20px"
                    btnName="Choose Slots"
                    textTransform="capitalize"
                    onClick={chooseYourBookSlot}
                  />
                </div>
                <div></div>
                {/* {paymentOption && (
                  <div className={Styles.showPayOption}>
                    <div className={Styles.showPayment}>
                      <p>
                        Payment Option : <span>{paymentOption}</span>{" "}
                      </p>
                      <span>
                        <i onClick={onShowPaymentModal} class="fa-solid fa-pen-to-square"></i>
                      </span>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
          <div className={Styles.modalArea__modal__buttonArea}>
            {/* <div>
              <Button
                // width="100%"
                marginTop="0"
                fontSize="20px"
                btnName="Choose Slots"
                textTransform="capitalize"
                onClick={chooseYourBookSlot}
              />
            </div> */}
            <div>
              <p className={Styles.modalArea__modal__buttonArea__timeSlot}>{slotDate}</p>
            </div>
            <div>
              <p className={Styles.modalArea__modal__buttonArea__timeSlot}>{slotTime}</p>
            </div>
            <div>
              <Button
                btnName="Cancel"
                fontSize="20px"
                marginTop="0"
                textTransform="capitalize"
                backgroundColor="#c4c4c4"
                border="1px solid #c4c4c4"
                color="#333"
                onClick={onCancelHandler}
              />
            </div>
            <div>
              <Button
                marginTop="0"
                onClick={() => onSubmitHandler("")}
                btnName={submit ? "Submit" : "Processing.."}
                fontSize="20px"
                textTransform="capitalize"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchMaking;
