import React, { useEffect, useState } from "react";
import About from "./About";
import company from "../../assets/banside.webp";
import style from "./index.module.scss";
import Banner2 from "../Home/Banner2";
import { endpoints } from "../../utils/Endpoints";
import axios from "axios";
import Loading from "../../components/Loading";

const AboutUs = () => {
  const [about, setAbout] = useState();
  const [aboutBan, setAboutBan] = useState("");
  const [showLoder, setShowLoder] = useState(true);

  const getAbout = {
    url: endpoints.aboutSetting,
    method: "GET",
  };

  useEffect(() => {
    axios(getAbout)
      .then((res) => {
        setAbout(res.data.data[0].content);
        setAboutBan(res.data.data[0].banner_about);
        setShowLoder(false);
      })
      .catch((err) => console.log(err));
      // eslint-disable-next-line
  }, []);

  return (
    <>
      {showLoder ? (
        <div style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Loading />
        </div>
      ) : (
        <>
          <Banner2 aboutBan={aboutBan} height="auto" />
          <div className={style.aboutUs}>
            <About about={about} showLoder={showLoder} />
            <div className={style.aboutUs__right}>
              <div className={style.aboutUs__right__company}>
                <img src={company} alt="company name" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AboutUs;
